import { IUser } from "../../api/interfaces/user";
import { IValidationError } from "../interfaces";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";

export interface IAuthState {
    isLoading: boolean;
    isAuthenticated: boolean;
    user?: IUser;
    loginErrors: IValidationError[];
}

interface ILoginSuccessAction {
    type: typeof LOGIN_SUCCESS;
    user: IUser;
}

interface ILoginFailedAction {
    type: typeof LOGIN_FAILED;
    errors: IValidationError[];
}

interface ILogoutSuccessAction {
    type: typeof LOGOUT_SUCCESS;
}

interface IGetUserSuccess {
    type: typeof GET_USER_SUCCESS;
    user: IUser;
}

interface IGetUserFailed {
    type: typeof GET_USER_FAILED;
}

export type AuthStateActionTypes =
    | ILoginSuccessAction
    | ILoginFailedAction
    | ILogoutSuccessAction
    | IGetUserSuccess
    | IGetUserFailed;
