import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    height: {
        property: 'height',
        scale: 'sizes',
    },
};
const height = system(config);
const heightProps = compose(height).propNames;

export { config, height, heightProps };
