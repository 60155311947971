import { NavLink as RouterLink } from "react-router-dom";
import styled, { DefaultTheme } from "styled-components";

import {
    FontColor,
    getButtonSize,
    getFontColor,
    getHoverColor,
    Size,
} from "../utils";

export interface ILinkProps {
    readonly color?: FontColor;
    readonly background?: LinkColor;
    size?: Size;
    fixedsize?: string;
}

export type LinkColor = "main" | "danger" | "white";

const getButtonColor = (theme: DefaultTheme, color?: LinkColor) => {
    return theme.buttonColors[color || "main"];
};

export const Link = styled(RouterLink)<ILinkProps>`
    padding: 0;
    border: none;
    font: inherit;
    color: ${(props) => getFontColor(props.theme, props.color)};
    background-color: transparent;
    cursor: pointer;
    width: ${(props) => (props.fixedsize ? `${props.fixedsize}` : "auto")};
`;

export const SecondaryLink = styled(Link)`
    padding: 1rem ${(props) => getButtonSize(props.size)};
    color: ${(props) => getFontColor(props.theme, props.color)};
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    border: 2px solid
        ${(props) => getButtonColor(props.theme, props.background)};
    border-radius: 100px;
`;

export const MenuLink = styled(Link)`
    && {
        display: flex;
        padding: 0.5rem;
        color: ${(props) => getFontColor(props.theme, props.color)};
        font-weight: bold;
        text-decoration: none;
        background-color: transparent;

        &:hover {
            background-color: transparent;
            color: ${(props) => getHoverColor(props.theme, props.color)};
        }

        &:focus {
            background-color: transparent;
            color: ${(props) => props.theme.fontColors.contrast};
        }

        svg {
            margin-right: 0.5rem;
        }
    }
`;
