import variant from '@styled-system/variant';

export const spaces = variant({
    prop: 'space',
    variants: {
        none: { p: 0, small: { py: 4 }, large: { py: 5 } },
        small: { p: 4, large: { px: 4, py: 5 }, none: { px: 4 } },
        large: { p: 5, small: { px: 5, py: 4 }, none: { px: 5 } },
    },
});

export const variants = variant({
    variants: {
        light: { boxShadow: 'light.default' },
        base: { boxShadow: 'base.default' },
        heavy: { boxShadow: 'heavy.default' },
        shadow: {
            boxShadow: 'base.default',
            ':hover': { boxShadow: 'base.hover' },
            ':active': { boxShadow: 'base.drag' },
            light: {
                boxShadow: 'light.default',
                ':hover': { boxShadow: 'light.hover' },
                ':active': { boxShadow: 'light.drag' },
            },
            heavy: {
                boxShadow: 'heavy.default',
                ':hover': { boxShadow: 'heavy.hover' },
                ':active': { boxShadow: 'heavy.drag' },
            },
        },
        border: { border: '2px solid', borderColor: 'card.border' },
    },
});
