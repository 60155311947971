import { Popover } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { FunctionComponent, useState } from "react";
import styled from "styled-components";

import { IconButton } from "../actions/buttons";
import { Text } from "./text";

export const Description: FunctionComponent = ({ children }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "popover" : undefined;

    if (!children) {
        return null;
    }

    return (
        <>
            <IconButton
                aria-describedby={id}
                onClick={handleClick}
                aria-label="Click for more info"
            >
                <InfoIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "bottom",
                }}
                transformOrigin={{
                    horizontal: "center",
                    vertical: "top",
                }}
            >
                <TextContainer>
                    <Text>{children}</Text>
                </TextContainer>
            </Popover>
        </>
    );
};

const TextContainer = styled.div`
    margin: 2rem;
`;
