import variant from '@styled-system/variant';

export const base = {
    appearance: 'none',
    position: 'relative',
    m: 0,
    pl: 0,
    border: 0,
    listStyle: 'none',
    '> li': {
        color: '#6D4981',
        transitionProperty:
            'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
        transitionDuration: '150ms',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        ':not(:disabled):hover': { color: '#49285B' },
    },
};

export const sizes = variant({
    prop: 'size',
    variants: {
        none: { '> li': { py: 0 } },
        small: { '> li': { py: 3 } },
        base: { '> li': { py: 4 } },
        large: { '> li': { py: 5 } },
    },
});
