import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import {
    ColorsProps,
    LayoutProps,
    SpaceProps,
    SxProp,
    TypographyProps,
} from '../../types';
import { base } from './SVG.styles';
import { space, spaceProps } from 'styles/collections/space';
import { colors, colorsProps } from 'styles/collections/colors';
import { layout, layoutProps } from 'styles/collections/layout';
import { typography, typographyProps } from 'styles/collections/typography';

export const SVGProps = createShouldForwardProp([
    ...(colorsProps as string[]),
    ...(layoutProps as string[]),
    ...(spaceProps as string[]),
    ...(typographyProps as string[]),
]);

export interface ISVG
    extends SxProp,
        ColorsProps,
        SpaceProps,
        TypographyProps,
        LayoutProps {
    size?: number;
    viewBox?: string;
    xmlns?: string;
    fill?: string;
}

const SVG = styled('svg', {
    shouldForwardProp: SVGProps,
})<ISVG>(
    ({ size, theme }) =>
        css({
            ...base,
            stroke: 'currentcolor',
            width: size,
            minWidth: size,
            height: size,
        })(theme),
    compose(colors, layout, space, typography),
    ({ sx, theme }) => css(sx)(theme),
);

SVG.displayName = 'SVG';
SVG.defaultProps = {
    size: 24,
    viewBox: '0 0 24 24',
    xmlns: 'http://www.w3.org/2000/svg',
};

export default SVG;
