import { useParams } from "react-router";
import { AppState } from "../../store";
import { useSelector } from "react-redux";

import { Score, useCountry } from "api/hooks/useCountries";
import { FlexGrid, FlexGridItem, H2 } from "@equalitycheck/eq-core";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "components/shared/table";
import { Link } from "react-router-dom";

const Company = () => {
    const { id } = useParams<{ id: string }>();
    const { country } = useCountry(id);
    const industries = useSelector(
        (state: AppState) => state.industryReducer.industries
    );

    if (!country) {
        return null;
    }

    return (
        <>
            <FlexGrid>
                <FlexGridItem>Country</FlexGridItem>
                <FlexGridItem>{id}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Number of companies</FlexGridItem>
                <FlexGridItem>{country?.companyCount}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Number of reviews</FlexGridItem>
                <FlexGridItem>{country.reviewCount}</FlexGridItem>
            </FlexGrid>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>opportunities</TableCell>
                        <TableCell>culture</TableCell>
                        <TableCell>bias</TableCell>
                        <TableCell>sexualAttention</TableCell>
                        <TableCell>workLife</TableCell>
                        <TableCell>communicatesDiversity</TableCell>
                        <TableCell>prioritizesDiversity</TableCell>
                        <TableCell>companyFuture</TableCell>
                        <TableCell>parentalLeave</TableCell>
                        <TableCell>rating</TableCell>
                        <TableCell>jokesRemarksMinorityPartOf</TableCell>
                        <TableCell>jokesRemarksMinorityNotPartOf</TableCell>
                        <TableCell>questionAbilitiesIdentity</TableCell>
                        <TableCell>average</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <ScoreRow title="All" score={country.scores.general.all} />
                    <ScoreRow
                        title="Majorities"
                        score={country.scores.ethnicities.majorities}
                    />
                    <ScoreRow
                        title="Minorities"
                        score={country.scores.ethnicities.minorities}
                    />
                    <ScoreRow
                        title="Female"
                        score={country.scores.genders.female}
                    />
                    <ScoreRow
                        title="Male"
                        score={country.scores.genders.male}
                    />
                    <ScoreRow
                        title="Other"
                        score={country.scores.genders.other}
                    />
                    <ScoreRow
                        title="Heterosexual"
                        score={country.scores.sexualities.heterosexual}
                    />
                    <ScoreRow
                        title="Lgbtqi"
                        score={country.scores.sexualities.lgbtqi}
                    />
                </TableBody>
            </Table>

            <H2>Industries</H2>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Industry</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(industries).map((industry) => (
                        <TableRow key={industry}>
                            <TableCell>{industries[industry]}</TableCell>
                            <TableCell>
                                <Link
                                    to={`/countries/${id}/industries/${industry}`}
                                >
                                    Link
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export const ScoreRow = ({ score, title }: { title: string; score: Score }) => (
    <TableRow>
        <TableCell>{title}</TableCell>
        <TableCell>{score.opportunities}</TableCell>
        <TableCell>{score.culture}</TableCell>
        <TableCell>{score.bias}</TableCell>
        <TableCell>{score.sexualAttention}</TableCell>
        <TableCell>{score.workLife}</TableCell>
        <TableCell>{score.communicatesDiversity}</TableCell>
        <TableCell>{score.prioritizesDiversity}</TableCell>
        <TableCell>{score.companyFuture}</TableCell>
        <TableCell>{score.parentalLeave}</TableCell>
        <TableCell>{score.rating}</TableCell>
        <TableCell>{score.jokesRemarksMinorityPartOf}</TableCell>
        <TableCell>{score.jokesRemarksMinorityNotPartOf}</TableCell>
        <TableCell>{score.questionAbilitiesIdentity}</TableCell>
        <TableCell>{score.average}</TableCell>
    </TableRow>
);

export default Company;
