import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    padding: {
        property: 'padding',
        scale: 'space',
    },
    paddingTop: {
        property: 'paddingTop',
        scale: 'space',
    },
    paddingRight: {
        property: 'paddingRight',
        scale: 'space',
    },
    paddingBottom: {
        property: 'paddingBottom',
        scale: 'space',
    },
    paddingLeft: {
        property: 'paddingLeft',
        scale: 'space',
    },
    paddingX: {
        properties: ['paddingRight', 'paddingLeft'],
        scale: 'space',
    },
    paddingY: {
        properties: ['paddingTop', 'paddingBottom'],
        scale: 'space',
    },
};
config.p = config.padding;
config.pt = config.paddingTop;
config.pr = config.paddingRight;
config.pb = config.paddingBottom;
config.pl = config.paddingLeft;
config.px = config.paddingX;
config.py = config.paddingY;

const padding = system(config);
const paddingProps = compose(padding).propNames;

export { config, padding, paddingProps };
