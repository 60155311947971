import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    offset: {
        property: 'offset',
        scale: 'offsets',
    },
};
const offset = system(config);
const offsetProps = compose(offset).propNames;

export { config, offset, offsetProps };
