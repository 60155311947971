const config: IConfig = {
    DEVELOPMENT: {
        API_URL: "http://localhost:8080",
    },
    PRODUCTION: {
        API_URL: "https://api.aws.equalitycheck.com/reviews",
    },
    STAGE: {
        API_URL: "https://api.stage.aws.equalitycheck.com/reviews",
    },
};

interface IConfig {
    [key: string]: {
        API_URL: string;
    };
}

export default config[process.env.REACT_APP_ENV || "DEVELOPMENT"];
