import { Link } from "react-router-dom";
import { useCountries } from "api/hooks/useCountries";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "components/shared/table";

const Countries = () => {
    const { countries } = useCountries();

    if (!countries) {
        return null;
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Country</TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {countries.map(({ countryCode }) => (
                    <TableRow key={countryCode}>
                        <TableCell>{countryCode}</TableCell>
                        <TableCell>
                            <Link to={`/countries/${countryCode}`}>Link</Link>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default Countries;
