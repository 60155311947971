import axios from "axios";
import { ConnectedRouter } from "connected-react-router";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as EmotionProvider } from "@emotion/react";
import { theme as eqTheme } from "@equalitycheck/eq-core"

import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store, { history } from "./store/index";
import { theme } from "./styled/theme";

Modal.setAppElement("#root");
axios.defaults.withCredentials = true;

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <EmotionProvider theme={eqTheme}>
            <BrowserRouter>
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <App />
                    </ConnectedRouter>
                </Provider>
            </BrowserRouter>
        </EmotionProvider>
    </ThemeProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
