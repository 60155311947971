import {
    Box,
    Flex,
    Small,
    ChevronDownIcon,
    Button,
    Card,
} from "@equalitycheck/eq-core";
import { FunctionComponent, useState } from "react";
import LeftNav from "../navigation/LeftNav";
import { Link } from "react-router-dom";
import { logout } from "../../api/auth";

const Layout: FunctionComponent = ({ children }) => {
    return (
        <Box bg="#F2F2F2" sx={{ minHeight: "100vh" }}>
            <LoggedInHeader />
            <Flex as="main" pt={7}>
                <LeftNav />
                <Box as="section" m={6} sx={{ flex: 1 }}>
                    {children}
                </Box>
            </Flex>
        </Box>
    );
};

const LoggedInHeader = () => {
    return (
        <Flex
            as="header"
            bg="white"
            position="fixed"
            width="full"
            flexDirection="column"
            zIndex={100}
            sx={{ boxShadow: "header" }}
        >
            <Flex as="nav" alignItems="center" flexShrink={0} py={4} px={5}>
                <Flex>
                    <Box
                        as="img"
                        width={96}
                        height={6}
                        //@ts-ignore
                        src="/assets/logo-desktop.svg"
                    />
                </Flex>
                <Box ml="auto">
                    <Account />
                </Box>
            </Flex>
        </Flex>
    );
};

const Account = () => {
    const [show, setShow] = useState(false);
    const logoutFn = async () => {
        await logout();
    }
    return (
        <>
            <Button variant="tertiary" onClick={() => setShow(!show)}>
                <Small mr={2}>Account</Small>
                <ChevronDownIcon
                    sx={{
                        transition: "transform 300ms ease-in-out",
                        transform: !show ? "none" : "rotateX(180deg)",
                    }}
                />
            </Button>
            {show && (
                <Card
                    p={4}
                    bg="white"
                    position="absolute"
                    top={7}
                    right={0}
                    display="flex"
                    minWidth={160}
                    sx={{
                        flexDirection: "column",
                        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
                    }}
                >
                    <Button
                        as={Link}
                        variant="tertiary"
                        onClick={logoutFn}
                        fontWeight="normal"
                        mb={3}
                    >
                        Logout
                    </Button>
                </Card>
            )}
        </>
    );
};

export default Layout;
