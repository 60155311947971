import {
    applyMiddleware,
    combineReducers,
    createStore,
    ReducersMapObject,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";

import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { bannerReducer } from "./banner/reducer";
import { countryReducer } from "./countries/reducer";
import { industryReducer } from "./industries/reducer";
import { companyReducer } from "./company/reducer";
import { userReducer } from "./user/reducer";

import { LOGOUT_SUCCESS } from "./user/types";

export const history = createBrowserHistory();

const staticReducers = {
    router: connectRouter(history),
    userReducer,
    bannerReducer,
    companyReducer,
    countryReducer,
    industryReducer,
};

const appState = combineReducers(staticReducers);

export type AppState = ReturnType<typeof appState>;

// Configure the store
function configureStore() {
    const middlewares = [routerMiddleware(history), ReduxThunk];
    const middleWareEnhancer = applyMiddleware(...middlewares);
    return createStore(
        createReducer(),
        composeWithDevTools(middleWareEnhancer)
    );
}

function createReducer<S>(asyncReducers?: ReducersMapObject<S, any>) {
    return resetEnhancer(
        combineReducers({
            ...staticReducers,
            ...asyncReducers,
        })
    );
}

const resetEnhancer = (rootReducer: any) => (state: any, action: any) => {
    if (action.type !== LOGOUT_SUCCESS) {
        return rootReducer(state, action);
    }
    const newState = rootReducer(undefined, {});
    newState.router = state.router;
    return newState;
};

const store = configureStore();

export default store;
