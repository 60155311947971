import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import {
    ColorsProps,
    LayoutProps,
    SpaceProps,
    SxProp,
    TypographyProps,
} from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { colors, colorsProps } from 'styles/collections/colors';
import { layout, layoutProps } from 'styles/collections/layout';
import { typography, typographyProps } from 'styles/collections/typography';
import { base, sizes as listSizeStyles } from './List.styles';

export const listProps = createShouldForwardProp([
    ...(colorsProps as string[]),
    ...(layoutProps as string[]),
    ...(spaceProps as string[]),
    ...(typographyProps as string[]),
]);

export interface IList
    extends SxProp,
        ColorsProps,
        SpaceProps,
        TypographyProps,
        LayoutProps {
    alternateBg?: 'odd' | 'even';
    bulleted?: boolean;
    divider?: string;
    size?: 'small' | 'base' | 'large' | 'none';
}

const List = styled('ul', {
    shouldForwardProp: listProps,
})<IList>(
    ({ alternateBg, bulleted, divider, theme }) =>
        css({
            ...(base as object),
            '> li': {
                borderBottom: divider ? '1px solid' : null,
                borderBottomColor: divider || null,
                borderTopWidth: divider ? `calc(1px * 0)` : null,
                borderBottomWidth: divider ? `calc(1px * (1 - 0))` : null,
                pl: bulleted ? '26px' : null,
                ':nth-of-type(even)': {
                    bg: alternateBg === 'even' ? '#F9F9F9' : undefined,
                },
                ':nth-of-type(odd)': {
                    bg: alternateBg === 'odd' ? '#F9F9F9' : undefined,
                },
                ':before': {
                    content: '""',
                    display: bulleted ? 'inline-block' : 'none',
                    position: 'absolute',
                    background: '#49285B',
                    borderRadius: 'circle',
                    left: 0,
                    mt: 3,
                    width: '10px',
                    height: '10px',
                },
            },
        })(theme),
    compose(listSizeStyles, colors, space, typography, layout),
    ({ sx, theme }) => css(sx)(theme),
);

List.displayName = 'List';
List.defaultProps = {
    bulleted: false,
    size: 'base',
};

export default List;
