import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import { ColorsProps, SpaceProps, SxProp, TypographyProps } from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { colors, colorsProps } from 'styles/collections/colors';
import { typography, typographyProps } from 'styles/collections/typography';

export const headerProps = createShouldForwardProp([
    ...(colorsProps as string[]),
    ...(spaceProps as string[]),
    ...(typographyProps as string[]),
]);

export type IHeader = SxProp & ColorsProps & SpaceProps & TypographyProps;

const Container: React.ComponentType<IHeader> = styled('header', {
    shouldForwardProp: headerProps,
})(
    ({ theme }) =>
        css({
            bg: 'white',
            position: 'fixed',
            top: 0,
            left: 0,
            width: 'full',
            flexDirection: 'column',
            boxShadow: 'header',
            zIndex: 100,
            boxSizing: 'border-box',
        })(theme),
    compose(colors, space, typography),
    ({ sx, theme }) => css(sx)(theme),
);

const Nav: React.ComponentType = styled('nav')(({ theme }) =>
    css({
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        px: 5,
        py: 4,
        boxSizing: 'border-box',
    })(theme),
);

const Header: React.ComponentType<IHeader> = ({ children, ...props }) => (
    <Container {...props}>
        <Nav>{children}</Nav>
    </Container>
);

Header.displayName = 'Header';

export default Header;
