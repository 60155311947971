import variant from '@styled-system/variant';

export const base = {
    m: 0,
    p: 0,
    minWidth: 0,
    fontFamily: 'body',
};

export const variants = variant({
    variants: {
        displayTwo: {
            fontSize: 'displayTwo',
            lineHeight: 'displayTwo',
            letterSpacing: 'displayTwo',
        },
        display: {
            fontSize: 'display',
            lineHeight: 'display',
            letterSpacing: 'display',
        },
        h1: {
            fontSize: 'h1',
            lineHeight: 'h1',
            letterSpacing: 'h1',
        },
        h2: {
            fontSize: 'h2',
            lineHeight: 'h2',
            letterSpacing: 'h2',
        },
        h3: {
            fontSize: 'h3',
            lineHeight: 'h3',
            letterSpacing: 'h3',
        },
        h4: {
            fontSize: 'h4',
            lineHeight: 'h4',
            letterSpacing: 'h4',
        },
        h5: {
            fontSize: 'h5',
            lineHeight: 'h5',
            letterSpacing: 'h5',
        },
        h6: {
            fontSize: 'h6',
            lineHeight: 'h6',
            letterSpacing: 'h6',
        },
    },
});
