import { Box, Card, Flex, H2, ChevronLeftIcon } from "@equalitycheck/eq-core";
import { Link } from "react-router-dom";

interface ICardLayoutProps {
    title: any;
    arrowHref?: string;
    maxWidth?: number;
}
const CardLayout: React.FunctionComponent<ICardLayoutProps> = ({
    title,
    children,
    maxWidth,
    arrowHref,
}) => {
    return (
        <>
            {arrowHref ? (
                <ArrowLeftIconLink href={arrowHref} label={title} />
            ) : (
                <H2 as="h1" mt={3}>
                    {title}
                </H2>
            )}
            <Card
                space="none"
                bg="white"
                mt={5}
                sx={{ maxWidth: maxWidth || 768 }}
                p={5}
            >
                {children}
            </Card>
        </>
    );
};

interface IArrowLeftIconLinkProps {
    href: string;
    label: string;
}

const ArrowLeftIconLink = ({ href, label }: IArrowLeftIconLinkProps) => {
    return (
        <Flex mt={3}>
            <Box
                as="button"
                mr={3}
                sx={{
                    appearance: "none",
                    bg: "transparent",
                    border: 0,
                    outline: 0,
                }}
            >
                <Link to={href}>
                    <ChevronLeftIcon size={24} />
                </Link>
            </Box>

            <H2 as="h1">{label}</H2>
        </Flex>
    );
};

export default CardLayout;
