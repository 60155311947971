import useSWR from "swr";
import { fetcher } from ".";
import { ISearchUser } from "../interfaces/user";

export const useUsers = (email: string, firstName: string, lastName: string) => {
    const { data, revalidate } = useSWR<ISearchUser[]>(`api/admin/users/search?email=${email}&firstName=${firstName}&lastName=${lastName}`, fetcher);

    return {
        users: data,
        revalidate
    }
}