export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILED = "GET_COUNTRIES_FAILED";

export interface ICountries {
    [key: string]: string;
}

export interface ICountriesState {
    isLoading: boolean;
    countries: ICountries;
}

interface IGetCountriesSuccessAction {
    type: typeof GET_COUNTRIES_SUCCESS;
    countries: ICountries;
}

interface IGetCountriesFailedAction {
    type: typeof GET_COUNTRIES_FAILED;
}

export type CountryStateActionTypes =
    | IGetCountriesSuccessAction
    | IGetCountriesFailedAction;
