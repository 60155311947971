export const debounce = <F extends (...args: any[]) => any>(
    func: F,
    waitFor: number
) => {
    let timeout: NodeJS.Timeout;

    const debounced = (...args: any[]) => {
        clearTimeout(timeout);
        return new Promise((resolve) => {
            timeout = setTimeout(() => resolve(func(...args)), waitFor);
        });
    };

    return (debounced as unknown) as (...args: Parameters<F>) => ReturnType<F>;
};
