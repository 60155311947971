import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    zIndex: {
        property: 'zIndex',
        scale: 'zIndices',
    },
};
const zIndex = system(config);
const zIndexProps = compose(zIndex).propNames;

export { config, zIndex, zIndexProps };
