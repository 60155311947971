// @ts-nocheck
import { Theme } from '@emotion/react';

const theme: Theme = {
    breakpoints: ['768px', '1040px', '1136px'],
    colors: {
        grey: '#F6F6F6',
        black: '#202020',
        border: '#E5E5E5',
        disabled: '#A3A3A3',
        placeholder: '#B9B9B9',
        brand: {
            primary: '#E73B66',
            secondary: '#FFC2BF',
            tertiary: '#FFECEB',
            hover: '#E42555',
            active: '#C81944',
        },
        semantic: {
            warning: {
                primary: '#F6C000',
                secondary: '#FFEDAC',
            },
            error: {
                primary: '#F13F3F',
                secondary: '#FFC1C1',
            },
            success: {
                primary: '#00AE31',
                secondary: '#BFF6C8',
            },
            passive: {
                primary: '#8FC0EC',
                secondary: '#EDF6FE',
            },
        },
        gradients: {
            purpleFire: ['#C031CC', '#9C34E9', '#622DEF'],
            orangeHaze: ['#F0AA42', '#E73B66', '#F04242'],
            iceQueen: ['#24D3DB', '#425EF0', '#8100E7'],
            redDelight: ['#F0AA42', '#F04242', '#F04242'],
            limonade: ['#F3EC32', '#B4ED3B', '#46B252'],
        },
        button: {
            primary: {
                default: '#E73B66',
                hover: '#E42555',
                focus: '#E73B66',
                active: '#C81944',
            },
            secondary: {
                default: 'white',
                hover: '#E73B66',
                focus: '#E73B66',
                active: '#C81944',
            },
            tertiary: {
                default: 'transparent',
                hover: '#E42555',
                focus: '#FFDBD9',
                active: '#C81944',
            },
        },
        card: {
            border: '#D0D0D0',
        },
        list: {
            divider: '#D0D0D0',
        },
        pill: {
            notClaimed: { bg: '#E6E6E6', color: '#8C8C8C' },
            claimed: { bg: '#C2F0D7', color: '#268C55' },
            premium: { bg: '#FFECB3', color: '#997400' },
        },
        tag: {
            default: '#FBDBE3',
            hover: '#ED638E',
        },
    },
    fonts: {
        body: 'Inter, sans-serif',
        heading: 'Inter, sans-serif',
        monospace: 'Menlo, monospace',
    },
    fontSizes: [
        '0.75rem', // 0 - 12px
        '0.875rem', // 1 - 14px
        '1rem', // 2 - 16px
        '1.125rem', // 3 - 18px
        '1.25rem', // 4 - 20px
        '1.563rem', // 5 - 25px
        '1.938rem', // 6 - 31px
        '2.438rem', // 7 - 39px
        '3rem', // 8 - 48px
        '3.875rem', // 9 - 62px
    ],
    fontWeights: [400, 600, 700, 900],
    layout: {
        container: {
            mobileSmall: {
                maxWidth: 576,
            },
            mobile: {
                maxWidth: 768,
            },
            tablet: {
                maxWidth: 1024,
            },
            desktop: {
                maxWidth: 1200,
            },
            desktopLarge: {
                maxWidth: 1360,
            },
        },
    },
    letterSpacings: ['0px', '0.1px', '0.2px', '0.3px'],
    lineHeights: ['16px', '24px', '32px', '48px', '64px', '80px'],
    offsets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    radii: {
        none: 0,
        small: 4,
        menuLink: 8,
        field: 8,
        large: 12,
        circle: 9999,
    },
    shadows: {
        header: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
        footer: '0px -2px 8px 0px rgba(0, 0, 0, 0.08)',
        field: {
            default: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            hover: '0 4px 4px 0 rgba(0, 0, 0, 0.08)',
            focus: '0px 0px 0px 2px #EBE0F1',
            active: '0px 0px 0px 2px #EBE0F1',
            error: '0px 0px 0px 2px #FBE3DE',
        },
        light: {
            default: '0 2px 4px 0 rgba(0, 0, 0, 0.16)',
            hover: '0 2px 16px 0 rgba(0, 0, 0, 0.16)',
            drag: '0 2px 24px 0 rgba(0, 0, 0, 0.16)',
        },
        base: {
            default: '0 2px 8px 0 rgba(0, 0, 0, 0.24)',
            hover: '0 2px 16px 0 rgba(0, 0, 0, 0.24)',
            drag: '0 2px 24px 0 rgba(0, 0, 0, 0.24)',
        },
        heavy: {
            default: '0 2px 8px 0 rgba(0, 0, 0, 0.32)',
            hover: '0 2px 16px 0 rgba(0, 0, 0, 0.32)',
            drag: '0 2px 24px 0 rgba(0, 0, 0, 0.32)',
        },
    },
    sizes: [0, 2, 4, 8, 16, 24, 32, 64, 128, 256, 512],
    space: [0, 2, 4, 8, 16, 24, 32, 64, 128, 256, 512],
    zIndices: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
};

theme.breakpoints.mobile = theme.breakpoints[0];
theme.breakpoints.tablet = theme.breakpoints[1];
theme.breakpoints.desktop = theme.breakpoints[2];

theme.fontSizes.caption = theme.fontSizes[0];
theme.fontSizes.small = theme.fontSizes[1];
theme.fontSizes.paragraph = theme.fontSizes[2];
theme.fontSizes.h6 = theme.fontSizes[2];
theme.fontSizes.h5 = theme.fontSizes[3];
theme.fontSizes.h4 = theme.fontSizes[4];
theme.fontSizes.h3 = theme.fontSizes[5];
theme.fontSizes.h2 = theme.fontSizes[6];
theme.fontSizes.h1 = theme.fontSizes[7];
theme.fontSizes.display = theme.fontSizes[8];
theme.fontSizes.displayTwo = theme.fontSizes[9];

theme.fontWeights.normal = theme.fontWeights[0];
theme.fontWeights.semiBold = theme.fontWeights[1];
theme.fontWeights.bold = theme.fontWeights[2];
theme.fontWeights.extraBold = theme.fontWeights[3];

theme.letterSpacings.displayTwo = theme.letterSpacings[3];
theme.letterSpacings.display = theme.letterSpacings[3];
theme.letterSpacings.h1 = theme.letterSpacings[2];
theme.letterSpacings.h2 = theme.letterSpacings[2];
theme.letterSpacings.h3 = theme.letterSpacings[1];
theme.letterSpacings.h4 = theme.letterSpacings[1];
theme.letterSpacings.h5 = theme.letterSpacings[1];
theme.letterSpacings.h6 = theme.letterSpacings[1];
theme.letterSpacings.paragraph = theme.letterSpacings[1];
theme.letterSpacings.small = theme.letterSpacings[1];
theme.letterSpacings.caption = theme.letterSpacings[1];

theme.lineHeights.displayTwo = theme.lineHeights[5];
theme.lineHeights.display = theme.lineHeights[4];
theme.lineHeights.h1 = theme.lineHeights[3];
theme.lineHeights.h2 = theme.lineHeights[3];
theme.lineHeights.h3 = theme.lineHeights[2];
theme.lineHeights.h4 = theme.lineHeights[2];
theme.lineHeights.h5 = theme.lineHeights[1];
theme.lineHeights.h6 = theme.lineHeights[1];
theme.lineHeights.paragraph = theme.lineHeights[1];
theme.lineHeights.small = theme.lineHeights[1];
theme.lineHeights.caption = theme.lineHeights[0];
theme.lineHeights.initial = '1';

theme.sizes.px = '1px';
theme.sizes.mobileSmall = '576px';
theme.sizes.mobile = theme.breakpoints[0];
theme.sizes.tablet = theme.breakpoints[1];
theme.sizes.desktop = theme.breakpoints[2];
theme.sizes.percent0 = '0%';
theme.sizes.percent10 = '10%';
theme.sizes.percent20 = '20%';
theme.sizes.percent30 = '30%';
theme.sizes.percent40 = '40%';
theme.sizes.percent50 = '50%';
theme.sizes.percent60 = '60%';
theme.sizes.percent70 = '70%';
theme.sizes.percent80 = '80%';
theme.sizes.percent90 = '90%';
theme.sizes.percent100 = '100%';
theme.sizes.zero = '0%';
theme.sizes.quarter = '25%';
theme.sizes.third = '33.33333333333333%';
theme.sizes.half = '50%';
theme.sizes.twoThirds = '66.66666666666666%';
theme.sizes.full = '100%';
theme.sizes.screen = '100vh';

theme.space.px = '1px';

theme.zIndices.none = theme.zIndices[0];
theme.zIndices.min = theme.zIndices[0];
theme.zIndices.levelOne = theme.zIndices[1];
theme.zIndices.levelTwo = theme.zIndices[2];
theme.zIndices.levelThree = theme.zIndices[3];
theme.zIndices.levelFour = theme.zIndices[4];
theme.zIndices.levelFive = theme.zIndices[5];
theme.zIndices.levelSix = theme.zIndices[6];
theme.zIndices.levelSeven = theme.zIndices[7];
theme.zIndices.levelEight = theme.zIndices[8];
theme.zIndices.levelNine = theme.zIndices[9];
theme.zIndices.levelTen = theme.zIndices[10];
theme.zIndices.max = theme.zIndices[10];

export default theme;
