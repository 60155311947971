import * as React from 'react';
import SVG from  '../SVG';

export type ITickIcon = React.ComponentProps<typeof SVG>;

const TickIcon: React.ComponentType<ITickIcon> = (props) => (
    <SVG {...props}>
        <path
            d="M10.0001 14.7804L7.68676 12.467C7.42676 12.207 7.00676 12.207 6.74676 12.467C6.48676 12.727 6.48676 13.147 6.74676 13.407L9.53342 16.1937C9.79342 16.4537 10.2134 16.4537 10.4734 16.1937L17.5268 9.14037C17.7868 8.88037 17.7868 8.46037 17.5268 8.20037C17.2668 7.94037 16.8468 7.94037 16.5868 8.20037L10.0001 14.7804Z"
            fill="currentColor"
        />
    </SVG>
);

export default TickIcon;
