import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    fontSize: {
        property: 'fontSize',
        scale: 'fontSizes',
    },
};
const fontSize = system(config);
const fontSizeProps = compose(fontSize).propNames;

export { config, fontSize, fontSizeProps };
