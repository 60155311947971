import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import CloseIcon from '../icons/Close';
import { LayoutProps, PositionProps, SpaceProps, SxProp } from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { layout, layoutProps } from 'styles/collections/layout';
import { position, positionProps } from 'styles/collections/position';
import { variants as alertVariantStyles } from './Alert.styles';

export const alertProps = createShouldForwardProp([
    ...(spaceProps as string[]),
    ...(layoutProps as string[]),
    ...(positionProps as string[]),
]);

export interface IAlertContainer
    extends SxProp,
        SpaceProps,
        LayoutProps,
        PositionProps {
    variant?: 'warning' | 'error' | 'success' | 'passive';
}

const Container = styled('div', {
    shouldForwardProp: alertProps,
})<IAlertContainer>(
    ({ theme }) =>
        css({
            appearance: 'none',
            border: '2px solid',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'body',
            p: '14px',
            borderRadius: 'large',
            transitionProperty:
                'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDuration: '150ms',
        })(theme),
    compose(alertVariantStyles, space, layout, position),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

const IconButton = styled('button')(({ theme }) =>
    css({
        appearance: 'none',
        bg: 'transparent',
        border: 0,
        outline: 0,
        flexShrink: 0,
        m: 0,
        p: 0,
        ml: 'auto',
        pl: 2,
    })(theme),
);

export interface IAlert extends SxProp, SpaceProps {
    onClick?: () => void;
    variant?: 'warning' | 'error' | 'success' | 'passive';
}

const Alert: React.ComponentType<IAlert> = ({
    children,
    onClick,
    variant,
    ...props
}) => {
    return (
        <Container variant={variant} {...props}>
            {children}
            {onClick ? (
                <IconButton onClick={onClick}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </Container>
    );
};
Alert.displayName = 'Alert';
Alert.defaultProps = {
    variant: 'passive',
};

export default Alert;
