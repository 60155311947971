import { useState } from "react";
import { Loading } from "../components/shared/animations/loading";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppState } from "../store";
import { useTop10 } from "../api/hooks/useTop10";
import { Form, Select } from "../components/shared/form";
import { ICompanyScore, IResponseCompany } from "../api/interfaces/company";
import { Flex, FlexGrid, FlexGridItem } from "@equalitycheck/eq-core";

const Top10 = () => {
    const countries = useSelector(
        (state: AppState) => state.countryReducer.countries
    );
    const [country, setCountry] = useState("NO");
    const [month, setMonth] = useState((new Date().getMonth() + 1).toString());
    const [year, setYear] = useState(new Date().getFullYear().toString());
    const countryOptions = Object.keys(countries).map((code) => ({
        label: countries[code],
        value: code,
    }));
    const monthOptions = [...Array(12).keys()].map((month) => ({
        label: (month + 1).toString(),
        value: (month + 1).toString(),
    }));
    const yearOptions = [2020, 2021].map((year) => ({
        label: year.toString(),
        value: year.toString(),
    }));

    const onCountryChange = (value: any) => {
        setCountry(value.value);
    };

    const onMonthChange = (value: any) => {
        setMonth(value.value);
    };

    const onYearChange = (value: any) => {
        setYear(value.value);
    };

    const { top10 } = useTop10(country, month, year);

    if (!top10) {
        return <Loading />;
    }

    return (
        <>
            <Form>
                <FlexGrid>
                    <FlexGridItem>
                        <Select
                            styles={{
                                valueContainer: (base) => ({
                                    ...base,
                                    minHeight: "60px",
                                }),
                            }}
                            id="select_month"
                            placeholder={"Month"}
                            options={monthOptions}
                            value={monthOptions.find(
                                (option) => option.value === month
                            )}
                            onChange={onMonthChange}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <Select
                            styles={{
                                valueContainer: (base) => ({
                                    ...base,
                                    minHeight: "60px",
                                }),
                            }}
                            id="select_year"
                            placeholder={"Year"}
                            options={yearOptions}
                            value={yearOptions.find(
                                (option) => option.value === year
                            )}
                            onChange={onYearChange}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <Select
                            styles={{
                                valueContainer: (base) => ({
                                    ...base,
                                    minHeight: "60px",
                                }),
                            }}
                            id="select_country"
                            placeholder={"Country"}
                            options={countryOptions}
                            value={countryOptions.find(
                                (option) => option.value === country
                            )}
                            onChange={onCountryChange}
                        />
                    </FlexGridItem>
                </FlexGrid>
            </Form>
            <Flex flexDirection="column">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Place</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Average</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {top10.map((t, i) => (
                            <Referrer key={i} place={i + 1} score={t} />
                        ))}
                    </TableBody>
                </Table>
            </Flex>
        </>
    );
};

const Referrer = ({
    place,
    score,
}: {
    place: number;
    score: IResponseCompany & ICompanyScore;
}) => {
    return (
        <TableRow>
            <TableCell>{place}</TableCell>
            <TableCell>{score.name}</TableCell>
            <TableCell>{score.average}</TableCell>
        </TableRow>
    );
};

export default Top10;
