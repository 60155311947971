import styled from "styled-components";

import { device } from "../../../styled/device";

type ToggleVisibility = true | false | [boolean, boolean];

interface IFlexProps {
    justifyContent?: "center" | "space-between" | "space-around" | "flex-end";
    alginItems?: "center" | "space-between" | "space-around" | "flex-end";
    direction?: "column";
    mobile?: ToggleVisibility;
    laptop?: ToggleVisibility;
    display?: "flex";
    paddingLeft?: number;
}

export const Flex = styled.div<IFlexProps>`
    display: flex;
    justify-content: ${(props) => props.justifyContent || "initial"};
    align-items: ${(props) => props.alginItems || "initial"};
    flex-direction: ${(props) => props.direction || "initial"};
    display: ${(props) =>
        props.mobile === false ? "none" : props.display || "flex"};
    padding-left: ${(props) => props.paddingLeft || 0}rem;

    @media ${device.laptop} {
        display: ${(props) =>
            props.laptop === false ? "none" : props.display || "flex"};
    }
`;

export const AuthContainer = styled.div`
    max-width: 520px;
    margin: 0 auto;
`;
