export const getItemWidthValue = (value: 'auto' | number = 'auto') => {
    if (value === 'auto') {
        return {
            flexBasis: 0,
            flexGrow: 1,
            maxWidth: 'full',
        };
    }

    if (typeof value === 'number') {
        if (value >= 0 && value <= 12) {
            return {
                flexBasis: `${(value / 12) * 100}%`,
                maxWidth: `${(value / 12) * 100}%`,
            };
        }
    }

    return null;
};
