import { compose, Config, system } from '@styled-system/core';
import getWidth from 'styles/utils/getWidth';

const config: Config = {
    width: {
        property: 'width',
        scale: 'sizes',
        transform: getWidth,
    },
};
const width = system(config);
const widthProps = compose(width).propNames;

export { config, width, widthProps };
