import styled from '@emotion/styled';
import Text, { IText } from '../Text';

export type ISmall = IText;

const Small = styled(Text)<ISmall>();

Small.displayName = 'Small';
Small.defaultProps = {
    as: 'p',
    color: 'black',
    clamp: false,
    variant: 'small',
};

export default Small;
