import CloseIcon from "@material-ui/icons/Close";
import { FunctionComponent, useState } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import {
    Button as SimpleButton,
    ButtonColor,
    LinkButton,
    SecondaryButton,
} from "../actions/buttons";
import { FontColor, Size } from "../utils";

export const customStyles = (size?: Size) => ({
    content: {
        bottom: "auto",
        left: "50%",
        marginRight: "-50%",
        maxHeight: "100vh",
        maxWidth: getMaxWidth(size),
        overflow: "auto",
        padding: 0,
        right: "auto",
        top: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1,
    },
});

const getMaxWidth = (size?: Size) => {
    switch (size) {
        case "m":
            return "550px";
        case "l":
            return "650px";
        case "xl":
            return "800px";
        default:
            return "800px";
    }
};

interface IModalProps {
    buttonText: string;
    title: string;
    buttonType?: "button" | "link";
    buttonColor?: FontColor;
    buttonBorderColor?: ButtonColor;
    size?: Size;
    trigger?: (props: any) => JSX.Element;
    disabled?: boolean;
}

export const Modal: FunctionComponent<IModalProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const Button =
        props.trigger || props.buttonType === "link"
            ? LinkButton
            : SecondaryButton;

    return (
        <>
            <Button
                color={props.buttonColor}
                background={props.buttonBorderColor}
                onClick={openModal}
                disabled={props.disabled}
            >
                {props.buttonText}
            </Button>
            <InternalModal
                isOpen={isOpen}
                onRequestClose={closeModal}
                size={props.size}
                title={props.title}
            >
                {props.children}
            </InternalModal>
        </>
    );
};

export interface IInjectedModalContentProps {
    closeModal: () => void;
}

type Diff<T, K> = Omit<T, keyof K>;

export function withEmptyModal<P>(ModalContent: React.ComponentType<P>) {
    return (
        props: Diff<P, IInjectedModalContentProps> & Omit<IModalProps, "title">
    ) => {
        const {
            buttonText,
            buttonType,
            buttonColor,
            buttonBorderColor: buttonBackgroundColor,
            size,
            trigger,
            ...rest
        } = props as Omit<IModalProps, "title">;

        const [isOpen, setIsOpen] = useState(false);

        const openModal = () => {
            setIsOpen(true);
        };

        const closeModal = () => {
            setIsOpen(false);
        };

        const Button =
            trigger || (buttonType === "link" ? LinkButton : SecondaryButton);

        return (
            <>
                {
                    <Button
                        color={buttonColor}
                        background={buttonBackgroundColor}
                        onClick={openModal}
                        aria-describedby={buttonText}
                    >
                        {buttonText}
                    </Button>
                }
                <ReactModal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    style={customStyles(size)}
                    contentLabel="Modal"
                >
                    <ModalContent closeModal={closeModal} {...(rest as P)} />
                </ReactModal>
            </>
        );
    };
}

export function withModal<P>(ModalContent: React.ComponentType<P>) {
    return (props: Diff<P, IInjectedModalContentProps> & IModalProps) => {
        const {
            buttonText,
            title,
            buttonType,
            buttonColor,
            buttonBorderColor: buttonBackgroundColor,
            size,
            trigger,
            disabled,
            ...rest
        } = props as IModalProps;

        const [isOpen, setIsOpen] = useState(false);

        const openModal = () => {
            setIsOpen(true);
        };

        const closeModal = () => {
            setIsOpen(false);
        };

        const Button =
            trigger || (buttonType === "link" ? LinkButton : SecondaryButton);

        return (
            <>
                {
                    <Button
                        color={buttonColor}
                        background={buttonBackgroundColor}
                        onClick={openModal}
                        aria-describedby={buttonText}
                        disabled={disabled}
                    >
                        {buttonText}
                    </Button>
                }
                <InternalModal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    size={props.size}
                    title={props.title}
                >
                    <ModalContent closeModal={closeModal} {...(rest as P)} />
                </InternalModal>
            </>
        );
    };
}

interface IInternalModalProps {
    isOpen: boolean;
    onRequestClose: (
        event:
            | React.MouseEvent<Element, MouseEvent>
            | React.KeyboardEvent<Element>
    ) => void;
    title: string;
    size?: Size;
}

export const InternalModal: FunctionComponent<IInternalModalProps> = ({
    isOpen,
    onRequestClose,
    title,
    size,
    children,
}) => {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles(size)}
            contentLabel="Modal"
        >
            <ModalHeader onRequestClose={onRequestClose}>{title}</ModalHeader>
            <ModalBody>{children}</ModalBody>
        </ReactModal>
    );
};

interface IModalHeaderProps {
    onRequestClose: (
        event:
            | React.MouseEvent<Element, MouseEvent>
            | React.KeyboardEvent<Element>
    ) => void;
    className?: string;
}

const UnstyledModalHeader: FunctionComponent<IModalHeaderProps> = ({
    children,
    className,
    onRequestClose,
}) => {
    return (
        <header className={className}>
            <div>
                <SimpleButton aria-label="close modal" onClick={onRequestClose}>
                    <CloseIcon />
                </SimpleButton>
            </div>
        </header>
    );
};

export const ModalHeader = styled(UnstyledModalHeader)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem;

    div {
        display: flex;
        justify-content: flex-end;
    }
`;

export const ModalBody = styled.section`
    padding: 0 2rem 2rem 2rem;
`;
