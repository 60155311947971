import styled, { DefaultTheme } from "styled-components";

import {
    FontColor,
    getButtonSize,
    getFontColor,
    getHoverColor,
    Size,
} from "../utils";

export type ButtonColor = "main" | "danger" | "white";

const getButtonColor = (theme: DefaultTheme, color?: ButtonColor) => {
    return theme.buttonColors[color || "main"];
};

interface IButtonProps {
    readonly color?: FontColor;
    readonly background?: ButtonColor;
    size?: Size;
    fixedsize?: string;
}

export const Button = styled.button<IButtonProps>`
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    width: ${(props) => (props.fixedsize ? `${props.fixedsize}` : "auto")};
`;

export const PrimaryButton = styled(Button)`
    padding: 1rem ${(props) => getButtonSize(props.size)};
    color: ${(props) => props.theme.fontColors.white};
    background-color: ${(props) =>
        getButtonColor(props.theme, props.background)};
    border: 2px solid
        ${(props) => getButtonColor(props.theme, props.background)};
    font-weight: bold;
    text-align: center;
    border-radius: 100px;
    max-height: 55px;

    &:disabled {
        opacity: 0.5;
    }
`;

export const SecondaryButton = styled(Button)`
    padding: 1rem ${(props) => getButtonSize(props.size)};
    color: ${(props) => getFontColor(props.theme, props.color)};
    font-weight: bold;
    text-align: center;
    border: 2px solid
        ${(props) => getButtonColor(props.theme, props.background)};
    border-radius: 100px;

    &:disabled {
        opacity: 0.5;
    }
`;

export const LinkButton = styled(Button)`
    display: block;
    padding: 0.5rem;
    color: ${(props) => getFontColor(props.theme, props.color)};
    font-weight: bold;
    text-align: center;

    &:hover {
        color: ${(props) => getHoverColor(props.theme, props.color)};
    }

    &:focus {
        color: ${(props) => props.theme.fontColors.contrast};
    }
`;

export const IconButton = styled(Button)`
    display: inline-flex;
    vertical-align: bottom;
    margin-left: 0.5rem;
`;

export const ButtonRow = styled.div`
    display: flex;
    flex-wrap: wrap;

    margin-top: -0.5rem;
    button,
    a {
        margin-top: 0.5rem;
        margin-right: 1rem;
    }
`;

export const ButtonGroup = styled.div`
    margin-top: 2rem;
    button {
        margin: 0 1rem;
    }
`;
