import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import { ColorsProps, SpaceProps, SxProp, TypographyProps } from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { colors, colorsProps } from 'styles/collections/colors';
import { typography, typographyProps } from 'styles/collections/typography';
import {
    labelVariants as radioLabelVariants,
    inputVariants as radioInputVariants,
} from './Radio.styles';
import Small from '../Small';

export const radioProps = createShouldForwardProp([
    ...(colorsProps as string[]),
    ...(spaceProps as string[]),
    ...(typographyProps as string[]),
]);

export interface IRadioLabel
    extends SxProp,
        ColorsProps,
        SpaceProps,
        TypographyProps {
    error?: boolean;
    disabled?: boolean;
    variant: 'base' | 'outline';
}

const Label: React.ComponentType<IRadioLabel> = styled('label', {
    shouldForwardProp: radioProps,
})(
    ({ theme }) =>
        css({
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            outline: 0,
        })(theme),
    compose(radioLabelVariants, colors, space, typography),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

Label.displayName = 'RadioLabel';
Label.defaultProps = {
    disabled: false,
    error: false,
};

interface IRadioInput
    extends Omit<React.ComponentPropsWithRef<'input'>, 'color'>,
        SxProp,
        ColorsProps,
        SpaceProps,
        TypographyProps {
    error?: boolean;
}
const Input: React.ComponentType<IRadioInput> = styled('input', {
    shouldForwardProp: radioProps,
})(
    ({ theme }) =>
        css({
            appearance: 'none',
            boxSizing: 'border-box',
            position: 'relative',
            bg: 'white',
            borderRadius: 'circle',
            mt: 0,
            mr: 3,
            ml: 0,
            width: 4,
            height: 4,
            outline: 0,
            pointerEvents: 'none',
            border: '2px solid',
        })(theme),
    compose(radioInputVariants, colors, space, typography),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

Input.displayName = 'RadioInput';
Input.defaultProps = {
    disabled: false,
    error: false,
};

export interface IRadio
    extends Omit<React.ComponentPropsWithoutRef<'input'>, 'color'>,
        SxProp,
        ColorsProps,
        SpaceProps,
        TypographyProps {
    error?: boolean;
    label?: string;
    variant: 'base' | 'outline';
}

const Radio = React.forwardRef<HTMLInputElement, IRadio>(
    ({ children, disabled, error, id, label, name, value, ...props }, ref) => (
        <Label
            aria-disabled={disabled ? 'true' : 'false'}
            data-error={error ? 'true' : 'false'}
            {...props}
        >
            <Input
                ref={ref}
                aria-disabled={disabled ? 'true' : 'false'}
                data-error={error ? 'true' : 'false'}
                type="radio"
                id={id}
                name={name}
                value={value}
                disabled={disabled}
                error={error}
                {...props}
            />
            {label ? (
                <Small
                    as="span"
                    color={disabled ? 'disabled' : 'black'}
                    sx={{ userSelect: 'none' }}
                >
                    {label}
                </Small>
            ) : (
                children
            )}
        </Label>
    ),
);

Radio.displayName = 'Radio';
Radio.defaultProps = {
    disabled: false,
    error: false,
};

export default Radio;
