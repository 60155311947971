import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import Button, { IButton } from '../Button';
import { base, sizes as tagSizeStyles } from './Tag.styles';

export interface ITag extends IButton {
    size?: 'small' | 'base';
}

const Tag = styled(Button)<ITag>(
    ({ theme }) => css(base)(theme),
    compose(tagSizeStyles),
);

Tag.displayName = 'Tag';
Tag.defaultProps = { size: 'base' };

export default Tag;
