import axios from "axios";
import {
    ICompanyUser,
    ICompanyUserClaim,
    IResponseCompany,
    IUnverifiedCompany,
    ICompanyRequest,
} from "./interfaces/company";
import config from "../config";

export const getCompanyByCountryCode = (code: string, search: string) =>
    axios.get<IResponseCompany[]>(
        `${config.API_URL}/api/admin/companies/search?search=${search}&countryCode=${code}`
    );

export const getCompany = (id: string) =>
    axios.get<{
        company: IResponseCompany;
        users: ICompanyUser[];
        claim?: ICompanyUserClaim;
    }>(`${config.API_URL}/api/admin/companies/${id}`);

export const createCompany = (company: ICompanyRequest) =>
    axios.post<IResponseCompany>(
        `${config.API_URL}/api/admin/companies`,
        company
    );

export const updateCompany = (id: string, company: FormData) =>
    axios.put<IResponseCompany>(
        `${config.API_URL}/api/admin/companies/${id}`,
        company
    );

export const getUnverifiedCompanies = () =>
    axios.get<IUnverifiedCompany[]>(
        `${config.API_URL}/api/admin/companies/unverified/`
    );

export const verifyCompany = (id: string) =>
    axios.put(`${config.API_URL}/api/admin/companies/${id}/verify`);

export const unverifyCompany = (id: string) =>
    axios.put(`${config.API_URL}/api/admin/companies/${id}/unverify`);

export const deleteCompany = (id: string) =>
    axios.delete(`${config.API_URL}/api/admin/companies/${id}`);

export const cacheStatistics = () =>
    axios.post(`${config.API_URL}/api/admin/companies/cache`);

export const removeCompanyOwner = (id: string) =>
    axios.post(`${config.API_URL}/api/admin/companies/${id}/removeOwner`);

export const mergeCompany = (id: string, mergeTo: string) =>
    axios.post(`${config.API_URL}/api/admin/companies/${id}/merge`, {
        companyId: mergeTo,
    });

export const uploadCompanies = (formData: FormData) =>
    axios.post<string>(
        `${config.API_URL}/api/admin/companies/upload`,
        formData
    );

export const setSearchPriorityOnCompanies = (formData: FormData) =>
    axios.post(
        `${config.API_URL}/api/admin/companies/setSearchPriority`,
        formData
    );

export const removeUser = (companyId: string, userId: string) =>
    axios.post<IResponseCompany>(
        `${config.API_URL}/api/admin/companies/${companyId}/removeUser`,
        {
            userId,
        }
    );

export const addUser = (companyId: string, userId: string) =>
    axios.post<IResponseCompany>(
        `${config.API_URL}/api/admin/companies/${companyId}/addUser`,
        {
            userId,
        }
    );
