import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    lineHeight: {
        property: 'lineHeight',
        scale: 'lineHeights',
    },
};
const lineHeight = system(config);
const lineHeightProps = compose(lineHeight).propNames;

export { config, lineHeight, lineHeightProps };
