import variant from '@styled-system/variant';
import { base as buttonBase } from '../Button/Button.styles';

export const base = {
    ...buttonBase,
    bg: 'tag.default',
    color: 'button.primary.focus',
    ':not(:disabled):hover': {
        bg: 'tag.hover',
        color: 'white',
        '> svg': { color: 'white' },
    },
    ':not(:disabled):focus': {
        boxShadow: 'inset 0 0 0 2px rgba(237, 99, 142, 1)',
    },
    ':not(:disabled):active': {
        bg: 'button.primary.active',
        boxShadow: 'inset 0 0 0 2px rgba(200, 25, 68, 1)',
        color: 'white',
        '> svg': { color: 'white' },
    },
    ':disabled': {
        bg: 'disabled',
        color: 'white',
        cursor: 'not-allowed',
        '> svg': { color: 'white' },
    },
    '> svg': {
        ml: 2,
        color: 'button.primary.default',
        pointerEvents: 'none',
        transitionProperty:
            'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
        transitionDuration: '150ms',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
};

export const sizes = variant({
    prop: 'size',
    variants: {
        small: { px: 4, py: 3, fontSize: 'caption', lineHeight: 'caption' },
        base: { px: 5, py: '12px', fontSize: 'small', lineHeight: 'paragraph' },
    },
});
