import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    fontWeight: {
        property: 'fontWeight',
        scale: 'fontWeights',
    },
};
const fontWeight = system(config);
const fontWeightProps = compose(fontWeight).propNames;

export { config, fontWeight, fontWeightProps };
