import { FunctionComponent } from "react";

import { RoundCard } from "../card";
import { Flex } from "../structure";
import { Text } from "../text";

import Check from "@material-ui/icons/Check";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import MailOutline from "@material-ui/icons/MailOutline";
import styled from "styled-components";

type BannerType = "success" | "error" | "info";

interface IBannerProps {
    type: BannerType;
    marginTop?: number;
}

export const Banner: FunctionComponent<IBannerProps> = ({
    children,
    type,
    marginTop,
}) => {
    const BannerIcon = getBannerIcon(type);
    return (
        <BannerContainer
            marginTop={marginTop}
            type={type}
            borderColor={
                type === "success"
                    ? "#00AE31"
                    : type === "info"
                    ? "#FFC700"
                    : "#e73b66"
            }
        >
            <Flex alginItems="center">
                <BannerIcon fontSize="large" />
                <Text color="main">{children}</Text>
            </Flex>
        </BannerContainer>
    );
};

const getBannerIcon = (type: BannerType) => {
    switch (type) {
        case "error":
            return PriorityHigh;
        case "success":
            return Check;
        case "info":
            return MailOutline;
    }
};

interface IBannerContainerProps {
    type: string;
    borderColor: string;
    marginTop?: number;
}

const BannerContainer = styled(RoundCard)<IBannerContainerProps>`
    margin-bottom: 0.5rem;
    margin-top: ${(props) => props.marginTop || 0}rem;
    background-color: ${(props) => props.theme.banner[props.type]};
    border: 1px solid ${(props) => props.borderColor};
    color: #fff;
    display: flex;

    svg {
        margin-right: 1rem;
        background-color: ${(props) => props.borderColor};
        border-radius: 50px;
        padding: 6px;
    }
`;
