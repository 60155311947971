import styled from '@emotion/styled';
import Heading, { IHeading } from '../Heading';

export type IH4 = IHeading;

const H4 = styled(Heading)<IH4>();

H4.displayName = 'H4';
H4.defaultProps = {
    as: 'h4',
    color: 'black',
    clamp: false,
    variant: 'h4',
};

export default H4;
