import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import {
    ColorsProps,
    LayoutProps,
    SpaceProps,
    SxProp,
    TypographyProps,
} from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { colors, colorsProps } from 'styles/collections/colors';
import { layout, layoutProps } from 'styles/collections/layout';
import { typography, typographyProps } from 'styles/collections/typography';
import { base } from './Textarea.styles';

export const textareaProps = createShouldForwardProp([
    ...(colorsProps as string[]),
    ...(layoutProps as string[]),
    ...(spaceProps as string[]),
    ...(typographyProps as string[]),
]);

export interface ITextarea
    extends Omit<React.ComponentPropsWithoutRef<'textarea'>, 'color'>,
        SxProp,
        ColorsProps,
        SpaceProps,
        TypographyProps,
        LayoutProps {
    disabled?: boolean;
    error?: boolean;
    resize?:
        | 'none'
        | 'both'
        | 'horizontal'
        | 'vertical'
        | 'initial'
        | 'inherit';
    ref?: React.ForwardedRef<HTMLTextAreaElement>;
}

const Textarea = styled('textarea', {
    shouldForwardProp: textareaProps,
})<ITextarea>(
    ({ error, resize, theme }) =>
        css({
            ...base,
            borderColor: error ? 'semantic.error.primary' : 'border',
            resize,
        })(theme),
    compose(colors, space, typography, layout),
    ({ sx, theme }) => css(sx)(theme),
);
Textarea.displayName = 'Textarea';
Textarea.defaultProps = {
    disabled: false,
    error: false,
    resize: 'none',
    rows: 4,
};

export default Textarea;
