import axios from "axios";
import config from "../config";

export const top10 = () => axios.post(`${config.API_URL}/api/admin/jobs/top10`);
export const dailyFollowerSummary = () =>
    axios.post(`${config.API_URL}/api/admin/jobs/dailyFollowerSummary`);
export const index = () => axios.post(`${config.API_URL}/api/admin/jobs/index`);
export const cacheLatest = () =>
    axios.post(`${config.API_URL}/api/admin/jobs/cacheLatest`);

export const deleteAllData = () =>
    axios.post(`${config.API_URL}/api/admin/jobs/deleteAll`);

export const upload = (page: number) =>
    axios.post(`${config.API_URL}/api/admin/jobs/upload`, {
        page,
    });
