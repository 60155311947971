import styled from '@emotion/styled';
import Heading, { IHeading } from '../Heading';

export type IDisplayTwo = IHeading;

const DisplayTwo = styled(Heading)<IDisplayTwo>();

DisplayTwo.displayName = 'DisplayTwo';
DisplayTwo.defaultProps = {
    as: 'h1',
    color: 'black',
    clamp: false,
    variant: 'displayTwo',
};

export default DisplayTwo;
