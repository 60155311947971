import { IResponseCompany } from "../../api/interfaces/company";
import { IValidationError } from "../interfaces";

export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_VALIDATION_ERROR =
    "CREATE_COMPANY_VALIDATION_ERROR";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const ERROR = "ERROR";

export interface ICompanyState {
    isLoading: boolean;
    companies: IResponseCompany[];
    validationErrors: IValidationError[];
}

interface IGetCompaniesSuccess {
    type: typeof GET_COMPANIES_SUCCESS;
    companies: IResponseCompany[];
}

interface ICreateCompanySuccess {
    type: typeof CREATE_COMPANY_SUCCESS;
    company: IResponseCompany;
}

interface ICreateCompanyValidationError {
    type: typeof CREATE_COMPANY_VALIDATION_ERROR;
    errors: IValidationError[];
}

interface IUpdateCompanySuccess {
    type: typeof UPDATE_COMPANY_SUCCESS;
    company: IResponseCompany;
}

interface IError {
    type: typeof ERROR;
}

export type CompanyStateActionTypes =
    | IGetCompaniesSuccess
    | ICreateCompanySuccess
    | IUpdateCompanySuccess
    | ICreateCompanyValidationError
    | IError;
