import useSWR from "swr";
import { fetcher } from ".";
import { ICompanyScore, IResponseCompany } from "../interfaces/company";

export const useTop10 = (countryCode: string, month: string, year: string) => {
    const { data } = useSWR<(IResponseCompany & ICompanyScore)[]>(
        `api/admin/companies/top10?countryCode=${countryCode}&month=${month}&year=${year}`,
        fetcher
    );

    return {
        top10: data,
    };
};
