import { Dispatch } from "redux";

import { getCompanyByCountryCode } from "../../api/company";
import { ERROR, GET_COMPANIES_SUCCESS } from "./types";

export const getCompaniesAction = (
    countryCode: string,
    search: string
) => async (dispatch: Dispatch) => {
    try {
        const response = await getCompanyByCountryCode(countryCode, search);
        const companies = await response.data;
        dispatch({ type: GET_COMPANIES_SUCCESS, companies });
    } catch (e) {
        dispatch({ type: ERROR });
    }
};
