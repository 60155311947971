import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import { forwardRef, useState } from "react";
import styled from "styled-components";
import { ValidationError } from "../errors/validation";
import { IValidationError } from "../../../store/interfaces";
import { IconButton } from "../actions/buttons";
import { Label } from "./index";

interface IInputButton {
    icon: (props: any) => JSX.Element;
    props: React.ComponentPropsWithRef<typeof IconButton>;
}

interface ITextInputProps {
    autoFocus?: boolean;
    id: string;
    label: string;
    name: string;
    value?: number | string;
    icon?: string;
    serverError: IValidationError[];
    className?: string;
    predicted?: string;
    type?: "text" | "password" | "email";
    required?: boolean;
    placeholder?: string;
    onValueChange: (name: string, value: string) => void;
    button?: IInputButton;
}

export const TextInput = forwardRef<HTMLInputElement, ITextInputProps>(({
    id,
    autoFocus,
    name,
    value,
    serverError,
    label,
    onValueChange,
    required,
    type = "text",
    placeholder,
    button,
    icon }, ref) => {
    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onValueChange(name, event.currentTarget.value);
    };

    const hasError = serverError?.some((error) => error.param === name);
    return (
        <Label>
            {label}
            <InputWrapper icon={icon}>
                <Input
                    ref={ref}
                    hasIcon={!!icon}
                    hasButton={true}
                    autoFocus={autoFocus}
                    type={type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required={required}
                    placeholder={placeholder}
                    hasError={!!hasError}
                    aria-describedby={!!hasError ? name + "error" : undefined}
                    aria-invalid={!!hasError}
                />
                <InputButton button={button} />
            </InputWrapper>
            <ValidationError
                id={name + "error"}
                errors={serverError}
                param={name}
            />
        </Label>
    );
});

export const PasswordInput = (props: ITextInputProps) => {
    const [passwordType, setPasswordType] = useState<"password" | "text">(
        "password"
    );

    const onClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();
        setPasswordType(passwordType === "password" ? "text" : "password");
    };

    return (
        <TextInput
            {...props}
            type={passwordType}
            button={{
                icon: RemoveRedEyeIcon,
                props: {
                    onClick,
                },
            }}
        />
    );
};

const InputWrapper = styled.div<{ icon?: string }>`
    position: relative;
    width: 100%;
    margin-top: 0.5rem;

    &::before {
        font-family: "Material Icons";
        content: "${(props) => props.icon}";
        display: ${(props) => (props.icon ? "flex" : "none")};
        position: absolute;
        color: #4b2461;
        width: 2rem;
        height: 100%;
        font-size: 20px;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border: 1px solid #979797;
        border-right: none;
    }
`;

const Input = styled.input<{
    hasIcon?: boolean;
    hasButton?: boolean;
    hasError?: boolean;
}>`
    width: 100%;
    font-size: 16px;
    padding: 1rem;
    border: 1px solid ${(props) => (props.hasError ? "#e73b66" : "#979797")};
    border-radius: 4px;
    padding-left: ${(props) => (props.hasIcon ? "2rem" : null)};
    padding-right: ${(props) => (props.hasButton ? "2rem" : null)};
`;

const InputButton = ({ button }: { button?: IInputButton }) => {
    if (!button) {
        return null;
    }

    const { props, icon: Icon } = button;

    return (
        <InputIcon>
            <IconButton {...props} type="button">
                <Icon />
            </IconButton>
        </InputIcon>
    );
};

const InputIcon = styled.div`
    display: flex;
    position: absolute;
    padding-right: 1rem;
    right: 0;
    top: 0;
    color: #4b2461;
    width: 2rem;
    height: 100%;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #979797;
    border-left: none;
`;
