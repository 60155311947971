import {
    CompanyStateActionTypes,
    CREATE_COMPANY_SUCCESS,
    CREATE_COMPANY_VALIDATION_ERROR,
    ERROR,
    GET_COMPANIES_SUCCESS,
    ICompanyState,
    UPDATE_COMPANY_SUCCESS,
} from "./types";

const initialState: ICompanyState = {
    companies: [],
    isLoading: true,
    validationErrors: [],
};

export const companyReducer = (
    state = initialState,
    action: CompanyStateActionTypes
): ICompanyState => {
    switch (action.type) {
        case GET_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.companies,
                isLoading: false,
            };
        case CREATE_COMPANY_SUCCESS:
            return {
                companies: [...state.companies, action.company],
                isLoading: false,
                validationErrors: [],
            };
        case UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                companies: state.companies.map((c) =>
                    c.id === action.company.id ? action.company : c
                ),
                isLoading: false,
            };
        case CREATE_COMPANY_VALIDATION_ERROR:
            return {
                ...state,
                isLoading: false,
                validationErrors: action.errors,
            };
        case ERROR:
            return {
                ...state,
                companies: [],
                isLoading: false,
            };
        default:
            return state;
    }
};
