import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    minHeight: {
        property: 'minHeight',
        scale: 'sizes',
    },
};
const minHeight = system(config);
const minHeightProps = compose(minHeight).propNames;

export { config, minHeight, minHeightProps };
