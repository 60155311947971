import styled from '@emotion/styled';
import Heading, { IHeading } from '../Heading';

export type IH5 = IHeading;

const H5 = styled(Heading)<IH5>();

H5.displayName = 'H5';
H5.defaultProps = {
    as: 'h5',
    color: 'black',
    clamp: false,
    variant: 'h5',
};

export default H5;
