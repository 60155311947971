import { push } from "connected-react-router";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { SuggestionSelectedEventData } from "react-autosuggest";
import { PrimaryButton } from "../../components/shared/actions/buttons";
import { MenuLink } from "../../components/shared/actions/link";
import { Autosuggest, Form } from "../../components/shared/form";
import { debounce } from "../../utils/debounce";
import { getCompanyByCountryCode } from "../../api/company";
import { ISimpleSearchCompany } from "../../api/interfaces/company";
import { IResponseCompany } from "../../api/interfaces/company";
import { AppState } from "../../store";
import { getCompaniesAction } from "../../store/company/actions";

import { Flex, FlexGrid, FlexGridItem } from "@equalitycheck/eq-core";

const load = debounce(
    async (country: string, search: string, cancel = false) => {
        if (cancel) {
            return [];
        }
        const companies = await getCompanyByCountryCode(country, search);
        return companies.data.slice(0, 10);
    },
    500
);

const Companies = () => {
    const searchedCompanies = useSelector<AppState, IResponseCompany[]>(
        (state) => state.companyReducer.companies
    );
    const countries = useSelector(
        (state: AppState) => state.countryReducer.countries
    );
    const dispatch = useDispatch();
    const [country, setCountry] = useState("NO");
    const [company, setCompany] = useState("");
    const [companies, setCompanies] = useState<ISimpleSearchCompany[]>([]);

    const countryOptions = Object.keys(countries).map((code) => ({
        label: countries[code],
        value: code,
    }));

    const onCountryChange = (value: any) => {
        setCountry(value.value);
    };

    const onCompanyChange = (event: React.FormEvent<any>, params: any) => {
        setCompany(event.currentTarget.value);
    };

    const loadCompanies = async ({ value }: any) => {
        if (country && value.length > 0) {
            const suggestions = await load(country, value);
            setCompanies(suggestions);
        }
    };

    const clearCompanies = () => {
        setCompanies([]);
    };

    const getSuggestionValue = (c: ISimpleSearchCompany) => c.id.toString();

    const renderSuggestion = (c: ISimpleSearchCompany) => <span>{c.name}</span>;

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        load(country, company, true);
        dispatch(getCompaniesAction(country, company));
    };

    const onSuggestionSelected = (
        event: React.FormEvent<any>,
        data: SuggestionSelectedEventData<ISimpleSearchCompany>
    ) => {
        dispatch(push(`/companies/${data.suggestion.id}`));
    };

    const inputProps = {
        onChange: onCompanyChange,
        placeholder: "Search for company",
        value: company,
    };

    return (
        <div>
            <Flex alignItems="center">
                <h1>Search</h1>
            </Flex>
            <Form onSubmit={onSubmit}>
                <FlexGrid wrap="wrap">
                    <FlexGridItem>
                        <Autosuggest
                            suggestions={companies}
                            onSuggestionsFetchRequested={loadCompanies}
                            onSuggestionsClearRequested={clearCompanies}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                            onSuggestionSelected={onSuggestionSelected}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <Select
                            styles={{
                                valueContainer: (base) => ({
                                    ...base,
                                    minHeight: "60px",
                                }),
                            }}
                            id="select_country"
                            placeholder={"Country"}
                            options={countryOptions}
                            value={countryOptions.find(
                                (option) => option.value === country
                            )}
                            onChange={onCountryChange}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <PrimaryButton size="xl" type="submit">
                            Search
                        </PrimaryButton>
                    </FlexGridItem>
                </FlexGrid>
            </Form>
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Company Number (VAT)</TableCell>
                            <TableCell>Claimed</TableCell>
                            <TableCell>Plan</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchedCompanies.map((c) => (
                            <CompanyRow key={c.id} companyStatistic={c} />
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

const CompanyRow = ({
    companyStatistic,
}: {
    companyStatistic: IResponseCompany;
}) => {
    return (
        <TableRow>
            <TableCell>{companyStatistic.name}</TableCell>
            <TableCell>{companyStatistic.companyNumber}</TableCell>
            <TableCell>{companyStatistic.claimed ? "Claimed" : ""}</TableCell>
            <TableCell>{companyStatistic.plan}</TableCell>
            <TableCell>
                <MenuLink to={`/companies/${companyStatistic.id}`}>
                    Show
                </MenuLink>
            </TableCell>
        </TableRow>
    );
};

export default Companies;
