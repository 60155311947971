import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    color: {
        property: 'color',
        scale: 'colors',
    },
};
config.textColor = config.color;

const color = system(config);
const colorProps = compose(color).propNames;

export { config, color, colorProps };
