import { CLEAR_BANNER, SHOW_ERROR_BANNER, SHOW_SUCCESS_BANNER } from "./types";

export const clearBanner = () => ({
    type: CLEAR_BANNER,
});

export const showErrorBanner = (message: string) => ({
    message,
    type: SHOW_ERROR_BANNER,
});

export const showSuccessBanner = (message: string) => ({
    message,
    type: SHOW_SUCCESS_BANNER,
});
