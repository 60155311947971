import React, { useState } from "react";
import axios from "axios";
import {
    Box,
    Button,
    Card,
    FlexGrid,
    FlexGridItem,
    FormField,
    H4,
    Input,
} from "@equalitycheck/eq-core";

import { Form } from "../components/shared/form";
import config from "../config";
import { useForm } from "react-hook-form";

const Statistics = () => {
    return (
        <>
            <FlexGrid>
                <FlexGridItem width={4}>
                    <Download
                        title="Download Review Data"
                        url={`${config.API_URL}/api/admin/reviews/raw`}
                    />
                </FlexGridItem>
                <FlexGridItem width={4}>
                    <Download
                        title="Download Corona Data"
                        url={`${config.API_URL}/api/admin/corona/raw`}
                    />
                </FlexGridItem>
                <FlexGridItem width={4}>
                    <Download
                        title="Download Salary Data"
                        url={`${config.API_URL}/api/admin/salaries/raw`}
                    />
                </FlexGridItem>
                <FlexGridItem width={4}>
                    <Download
                        title="Download Comments Data"
                        url={`${config.API_URL}/api/admin/comments/raw`}
                    />
                </FlexGridItem>
                <FlexGridItem width={4}>
                    <Download
                        title="Download Comments Data with reviews"
                        url={`${config.API_URL}/api/admin/comments/reviews`}
                    />
                </FlexGridItem>
                <FlexGridItem width={4}>
                    <Download
                        title="Download panelbaseIds from reviews"
                        url={`${config.API_URL}/api/admin/reviews/panelbaseids`}
                    />
                </FlexGridItem>
                <FlexGridItem width={4}>
                    <Download
                        title="Download all saved panelbaseIds"
                        url={`${config.API_URL}/api/admin/reviews/panelbaseids/all`}
                    />
                </FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem width={8}>
                    <StatisticsForm />
                </FlexGridItem>
            </FlexGrid>
        </>
    );
};

interface IDownloadProps {
    title: string;
    url: string;
}

const StatisticsForm = () => {
    const { handleSubmit, register } = useForm<{
        minEmployees?: number;
        maxEmployees?: number;
        minIndicatorScore?: number;
        minGroupScore?: number;
    }>();

    const onSubmit = handleSubmit(async (data) => {
        axios
            .post(`${config.API_URL}/api/admin/companies/statistics`, data, {
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `statistics.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
    });

    return (
        <Card bg="white">
            <H4>Download company statistics</H4>
            <Box as="form" onSubmit={onSubmit}>
                <FlexGrid>
                    <FlexGridItem width={6}>
                        <FormField
                            as={Input}
                            label={"GroupScore"}
                            id="minGroupScore"
                            {...register("minGroupScore")}
                            name="minGroupScore"
                            type="string"
                            mt={3}
                        />
                    </FlexGridItem>
                    <FlexGridItem width={6}>
                        <FormField
                            as={Input}
                            label={"IndicatorScore"}
                            id="minIndicatorScore"
                            {...register("minIndicatorScore")}
                            name="minIndicatorScore"
                            type="string"
                            mt={3}
                        />
                    </FlexGridItem>
                    <FlexGridItem width={6}>
                        <FormField
                            as={Input}
                            label={"Min employees"}
                            id="minEmployees"
                            {...register("minEmployees")}
                            name="minEmployees"
                            type="string"
                            mt={3}
                        />
                    </FlexGridItem>
                    <FlexGridItem width={6}>
                        <FormField
                            as={Input}
                            label={"Max employees"}
                            id="maxEmployees"
                            {...register("maxEmployees")}
                            name="maxEmployees"
                            type="string"
                            mt={3}
                        />
                    </FlexGridItem>
                </FlexGrid>
                <Button mt={3} type="submit">
                    Download
                </Button>
            </Box>
        </Card>
    );
};

const Download = ({ title, url }: IDownloadProps) => {
    const [password, setPassword] = useState("");
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        axios
            .post(url, { password }, { responseType: "blob" })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${title}.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
    };

    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    return (
        <Card bg="white">
            <H4>{title}</H4>
            <Form onSubmit={onSubmit}>
                <Input
                    id="password"
                    type="password"
                    name="password"
                    onChange={onPasswordChange}
                    value={password}
                />
                <Button disabled={false} type="submit">
                    Download
                </Button>
            </Form>
        </Card>
    );
};

export default Statistics;
