import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import {
    ColorsProps,
    FlexboxProps,
    LayoutProps,
    PositionProps,
    SpaceProps,
    SxProp,
    TypographyProps,
} from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { colors, colorsProps } from 'styles/collections/colors';
import { typography, typographyProps } from 'styles/collections/typography';
import { flexbox, flexboxProps } from 'styles/collections/flexbox';
import { layout, layoutProps } from '@/styles/collections/layout';
import { position, positionProps } from '@/styles/collections/position';

export const flexProps = createShouldForwardProp([
    ...(colorsProps as string[]),
    ...(layoutProps as string[]),
    ...(spaceProps as string[]),
    ...(typographyProps as string[]),
    ...(flexboxProps as string[]),
    ...(positionProps as string[]),
]);

export type IFlex = SxProp &
    ColorsProps &
    SpaceProps &
    TypographyProps &
    FlexboxProps &
    LayoutProps &
    PositionProps;

const Flex = styled('div', {
    shouldForwardProp: flexProps,
})<IFlex>(
    ({ theme }) =>
        css({
            display: 'flex',
            m: 0,
            p: 0,
            boxSizing: 'border-box',
        })(theme),
    compose(colors, space, typography, flexbox, layout, position),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

Flex.displayName = 'Flex';

export default Flex;
