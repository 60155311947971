import useSWR from "swr";
import { fetcher } from ".";
import {
    ICompanyScore,
    ICompanyUser,
    ICompanyUserClaim,
    IResponseCompany,
} from "../interfaces/company";

export const useCompany = (companyId?: string) => {
    const { data, isValidating, mutate } = useSWR<{
        company: IResponseCompany;
        users: ICompanyUser[];
        claim?: ICompanyUserClaim;
        scores: ICompanyScore[];
    }>(companyId ? `api/admin/companies/${companyId}` : null, fetcher);

    return {
        companyResponse: data,
        isLoading: isValidating,
        mutate,
    };
};
