const hasDefault = (x: unknown): x is { default: string | number } =>
    typeof x === 'object' && x !== null && 'default' in x;

export default function get(
    obj: object,
    key?: string | number,
    def?: unknown,
    p?: number,
    undef?: unknown,
) {
    const path = key && typeof key === 'string' ? key.split('.') : [key];

    for (p = 0; p < path.length; p++) {
        obj = obj ? (obj as any)[path[p]!] : undef;
    }

    if (obj === undef) return def;

    return hasDefault(obj) ? obj.default : obj;
}
