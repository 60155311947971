import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import {
    ColorsProps,
    FlexGridProps,
    LayoutProps,
    PositionProps,
    SxProp,
} from '../../types';
import { flexbox, flexboxProps } from 'styles/collections/flexbox';
import { colors, colorsProps } from 'styles/collections/colors';
import { layout, layoutProps } from 'styles/collections/layout';
import { position, positionProps } from 'styles/collections/position';
import Flex from 'components/Flex';
import { getGapValue } from 'utils/index';
import FlexGridItem from '../FlexGridItem';

export const flexGridProps = createShouldForwardProp([
    ...(flexboxProps as string[]),
    ...(colorsProps as string[]),
    ...(layoutProps as string[]),
    ...(positionProps as string[]),
]);

export type IFlexGrid = SxProp &
    FlexGridProps &
    ColorsProps &
    LayoutProps &
    PositionProps;

const FlexGrid = styled(Flex, {
    shouldForwardProp: flexGridProps,
})<IFlexGrid>(
    ({ gap, theme }) =>
        css({
            boxSizing: 'border-box',
            flex: '0 1 auto',
            m: getGapValue(gap!, true),
            [FlexGridItem as any]: {
                p: getGapValue(gap!),
            },
        })(theme),
    compose(flexbox, colors, layout, position),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

FlexGrid.displayName = 'FlexGrid';
FlexGrid.defaultProps = {
    gap: 4,
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
};

export default FlexGrid;
