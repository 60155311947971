import styled from '@emotion/styled';
import Heading, { IHeading } from '../Heading';

export type IH2 = IHeading;

const H2 = styled(Heading)<IH2>();

H2.displayName = 'H2';
H2.defaultProps = {
    as: 'h2',
    color: 'black',
    clamp: false,
    variant: 'h2',
};

export default H2;
