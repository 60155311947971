import { compose, Config, system } from '@styled-system/core';
import { config as positionConfig } from 'styles/position';
import { config as topConfig } from 'styles/top';
import { config as rightConfig } from 'styles/right';
import { config as bottomConfig } from 'styles/bottom';
import { config as leftConfig } from 'styles/left';
import { config as zIndexConfig } from 'styles/zIndex';

const config: Config = {
    ...positionConfig,
    ...topConfig,
    ...rightConfig,
    ...bottomConfig,
    ...leftConfig,
    ...zIndexConfig,
};
const position = system(config);
const positionProps = compose(position).propNames;

export { config, position, positionProps };
