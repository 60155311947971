import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import Tippy, { TippyProps } from '@tippyjs/react/headless';
import { ColorsProps, SpaceProps, SxProp, TypographyProps } from '../../types';
import { space } from 'styles/collections/space';
import { colors } from 'styles/collections/colors';
import { typography } from 'styles/collections/typography';
import Box from '../Box';
import { base } from './Popover.styles';

export type IPopoverContainer = SxProp &
    ColorsProps &
    SpaceProps &
    TypographyProps;

const PopoverContainer = styled(Box)<IPopoverContainer>(
    ({ theme }) => css(base)(theme),
    compose(colors, space, typography),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);
PopoverContainer.displayName = 'PopoverContainer';

export type IPopover = TippyProps;

const Popover: React.ComponentType<IPopover> = ({ children, ...props }) => (
    <Tippy {...props}>{children}</Tippy>
);

Popover.displayName = 'Popover';
Popover.defaultProps = {
    placement: 'bottom',
    interactive: true,
    trigger: 'focus click',
};

export { PopoverContainer };
export default Popover;
