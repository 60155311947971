import * as React from 'react';
import SVG from  '../SVG';

export type IListIcon = React.ComponentProps<typeof SVG>;

const ListIcon: React.ComponentType<IListIcon> = (props) => (
    <SVG {...props}>
        <path
            d="M3 9H17V7H3V9ZM3 13H17V11H3V13ZM3 17H17V15H3V17ZM19 17H21V15H19V17ZM19 7V9H21V7H19ZM19 13H21V11H19V13Z"
            fill="currentcolor"
        />
    </SVG>
);

export default ListIcon;
