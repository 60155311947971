import { compose, Config, system } from '@styled-system/core';
import { config as alignItemsConfig } from 'styles/flexbox/alignItems';
import { config as alignContentConfig } from 'styles/flexbox/alignContent';
import { config as justifyItemsConfig } from 'styles/flexbox/justifyItems';
import { config as justifyContentConfig } from 'styles/flexbox/justifyContent';
import { config as flexConfig } from 'styles/flexbox/flex';
import { config as flexBasisConfig } from 'styles/flexbox/flexBasis';
import { config as flexDirectionConfig } from 'styles/flexbox/flexDirection';
import { config as flexGrowConfig } from 'styles/flexbox/flexGrow';
import { config as flexShrinkConfig } from 'styles/flexbox/flexShrink';
import { config as flexWrapConfig } from 'styles/flexbox/flexWrap';
import { config as justifySelfConfig } from 'styles/flexbox/justifySelf';
import { config as alignSelfConfig } from 'styles/flexbox/alignSelf';

const config: Config = {
    ...alignContentConfig,
    ...alignItemsConfig,
    ...alignSelfConfig,
    ...justifyContentConfig,
    ...justifyItemsConfig,
    ...justifySelfConfig,
    ...flexConfig,
    ...flexBasisConfig,
    ...flexDirectionConfig,
    ...flexGrowConfig,
    ...flexShrinkConfig,
    ...flexWrapConfig,
};
const flexbox = system(config);
const flexboxProps = compose(flexbox).propNames;

export { config, flexbox, flexboxProps };
