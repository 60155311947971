import { ComponentPropsWithoutRef, ComponentType } from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { ColorsProps, SpaceProps, SxProp, TypographyProps } from '../../types';
import { space } from 'styles/collections/space';
import { colors } from 'styles/collections/colors';
import { typography } from 'styles/collections/typography';
import Caption from 'components/Caption';

interface IStepperItem
    extends Omit<ComponentPropsWithoutRef<'span'>, 'color'>,
        SxProp,
        ColorsProps,
        SpaceProps,
        TypographyProps {
    current?: number;
}

const StepperItem: ComponentType<IStepperItem> = styled(Caption)(
    ({ theme }) => css({})(theme),
    compose(colors, space, typography),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

export default StepperItem;
