import styled from '@emotion/styled';
import Heading, { IHeading } from '../../components/Heading';

export type IDisplay = IHeading;

const Display = styled(Heading)<IDisplay>();

Display.displayName = 'Display';
Display.defaultProps = {
    as: 'h1',
    color: 'black',
    clamp: false,
    variant: 'display',
};

export default Display;
