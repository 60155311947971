import { useForm } from "react-hook-form";
import { useState } from "react";
import { Loading } from "../../components/shared/animations/loading";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import { ISearchUser } from "../../api/interfaces/user";
import { useUsers } from "../../api/hooks/useUsers";
import CardLayout from "../../components/shared/CardLayout";
import { MenuLink } from "../../components/shared/actions/link";
import { LinkButton } from "../../components/shared/actions/buttons";
import { Box, Flex, FormField, Input, Button } from "@equalitycheck/eq-core";
import { deleteUser } from "../../api/user";

type UserSearchFormData = {
    firstName: string;
    lastName: string;
    email: string;
};

const Users = () => {
    const [search, setSearch] = useState<UserSearchFormData>({
        email: "",
        firstName: "",
        lastName: "",
    });

    const { register, handleSubmit } = useForm<UserSearchFormData>();

    const onSubmit = handleSubmit(async (data) => {
        try {
            setSearch(data);
        } catch (e) {
            console.error(e);
        }
    });

    return (
        <CardLayout title="Users">
            <Flex flexDirection="column">
                <Box as="form" onSubmit={onSubmit}>
                    <Flex justifyContent="space-between">
                        <FormField
                            as={Input}
                            width="100%"
                            label="First name"
                            {...register("firstName")}
                            id="firstName"
                            name="firstName"
                            mr={4}
                            mt={3}
                        />
                        <FormField
                            as={Input}
                            width="100%"
                            label="Last name"
                            {...register("lastName")}
                            id="lastName"
                            name="lastName"
                            mr={4}
                            mt={3}
                        />
                        <FormField
                            width="100%"
                            as={Input}
                            label="Email"
                            {...register("email")}
                            id="email"
                            name="email"
                            mt={3}
                        />
                    </Flex>
                    <Button mt={4} type="submit">
                        Search
                    </Button>
                </Box>
                <Box>
                    <SearchResults search={search} />
                </Box>
            </Flex>
        </CardLayout>
    );
};

const SearchResults = ({ search }: { search: UserSearchFormData }) => {
    const { users, revalidate } = useUsers(
        search.email,
        search.firstName,
        search.lastName
    );

    const onDelete = () => {
        revalidate();
    };

    if (!users) {
        return <Loading />;
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>First name</TableCell>
                    <TableCell>Last name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {users.map((u, i) => (
                    <User key={i} user={u} onDeleteSuccess={onDelete} />
                ))}
            </TableBody>
        </Table>
    );
};

const User = ({
    user,
    onDeleteSuccess,
}: {
    user: ISearchUser;
    onDeleteSuccess: () => void;
}) => {
    const onDeleteClick = () => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            deleteUser(user.id);
            onDeleteSuccess();
        }
    };
    return (
        <TableRow>
            <TableCell>{user.firstName}</TableCell>
            <TableCell>{user.lastName}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>
                <Flex>
                    <MenuLink to={`/users/${user.id}/edit`}>Edit</MenuLink>
                    <LinkButton onClick={onDeleteClick}>Delete</LinkButton>
                </Flex>
            </TableCell>
        </TableRow>
    );
};

export default Users;
