import styled from '@emotion/styled';
import Box, { IBox } from '../Box';

export type IInlineBlock = IBox;

const InlineBlock = styled(Box)<IBox>({ display: 'inline-block' });

InlineBlock.displayName = 'InlineBlock';

export default InlineBlock;
