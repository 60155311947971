import {
    Flex,
    Box,
    Menu,
    MenuLink,
    DashboardIcon,
    ListIcon,
    BuildIcon,
} from "@equalitycheck/eq-core";
import { Link, useLocation } from "react-router-dom";

const links = [
    {
        title: "Dashboard",
        links: [
            {
                href: "/",
                icon: DashboardIcon,
                title: "Dashboard",
            },
        ],
    },
    {
        title: "Companies",
        links: [
            {
                href: "/companies",
                icon: ListIcon,
                title: "Companies",
            },
            {
                href: "/industries",
                icon: ListIcon,
                title: "Industries",
            },
            {
                href: "/countries",
                icon: ListIcon,
                title: "Countries",
            },
        ],
    },
    {
        title: "Users",
        links: [
            {
                href: "/users",
                icon: ListIcon,
                title: "Search",
            },
        ],
    },
    {
        title: "Technical",
        links: [
            {
                href: "/jobs",
                icon: BuildIcon,
                title: "Jobs",
            },
        ],
    },
];

const LeftNav = () => {
    const router = useLocation();

    const findCurrentMenuLink = (menu: { href: string }[]) => {
        const exactMatch = menu.find((m) => router.pathname === m.href);
        return (
            exactMatch || menu.find((m) => router.pathname.startsWith(m.href))
        );
    };

    const currentMenuLink = findCurrentMenuLink(links.flatMap((l) => l.links));

    return (
        <Flex as="aside" bg="white" width={9} py={6}>
            <Box flex={1}>
                {links.map((menu) => {
                    return (
                        <Menu
                            title={menu.title}
                            current={currentMenuLink?.href}
                            p={0}
                        >
                            {menu.links.map(({ href, icon: Icon, title }) => (
                                <MenuLink
                                    key={href}
                                    as={Link}
                                    // @ts-ignore
                                    to={href}
                                    id={href}
                                    icon={Icon}
                                >
                                    {title}
                                </MenuLink>
                            ))}
                        </Menu>
                    );
                })}
            </Box>
        </Flex>
    );
};

export default LeftNav;
