import { compose, Config, system } from '@styled-system/core';
import { config as displayConfig } from 'styles/display';
import { config as heightConfig } from 'styles/height';
import { config as widthConfig } from 'styles/width';
import { config as maxHeightConfig } from 'styles/maxHeight';
import { config as maxWidthConfig } from 'styles/maxWidth';
import { config as minHeightConfig } from 'styles/minHeight';
import { config as minWidthConfig } from 'styles/minWidth';
// import { config as sizeConfig } from 'styles/size';
import { config as overflowConfig } from 'styles/overflow';
import { config as overflowXConfig } from 'styles/overflowX';
import { config as overflowYConfig } from 'styles/overflowY';
import { config as verticalAlignConfig } from 'styles/verticalAlign';

const config: Config = {
    ...displayConfig,
    ...heightConfig,
    ...widthConfig,
    ...maxHeightConfig,
    ...maxWidthConfig,
    ...minHeightConfig,
    ...minWidthConfig,
    ...overflowConfig,
    ...overflowXConfig,
    ...overflowYConfig,
    // ...sizeConfig,
    ...verticalAlignConfig,
};
const layout = system(config);
const layoutProps = compose(layout).propNames;

export { config, layout, layoutProps };
