const size = {
    laptop: "1024px",
    mediumMobile: "374px",
    mobile: "768px",
};

export const device = {
    laptop: `(min-width: ${size.laptop})`,
    mediumMobile: `only screen and (max-width: ${size.mediumMobile})`,
    mobile: `only screen and (max-width: ${size.mobile})`,
};
