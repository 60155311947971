import styled from '@emotion/styled';
import Box, { IBox } from '../Box';

export type IInline = IBox;

const Inline = styled(Box)<IBox>({ display: 'inline' });

Inline.displayName = 'Inline';

export default Inline;
