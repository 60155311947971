import axios from "axios";
import { IUser } from "./interfaces/user";
import config from "../config";

export const login = (username: string, password: string) => {
    return axios.post<IUser>(`${config.API_URL}/api/auth`, {
        password,
        username,
    });
};

export const logout = () => {
    return axios.get(`${config.API_URL}/api/auth/logout`);
};
