import Unstyled, { AutosuggestProps } from "react-autosuggest";
import styled from "styled-components";

export const Autosuggest = <T extends {}, T2 extends {}>(
    props: AutosuggestProps<T, T2>
) => {
    return (
        <Wrapped>
            <Unstyled {...props} />
        </Wrapped>
    );
};

const Wrapped = styled.div`
    .react-autosuggest__container {
        position: relative;

        &::before {
            font-family: "Material Icons";
            content: "search";
            position: absolute;
            color: ${(props) => props.theme.fontColors.main};
            z-index: 345;
            width: 24px;
            height: 24px;
            font-size: 24px;
            top: 18px;
            left: 0.5rem;
        }
    }

    .react-autosuggest__input {
        width: 100%;
        font-size: 16px;
        padding: 1rem 1rem 1rem 2.5rem;
        border: 1px solid hsl(0, 0%, 80%);
        border-radius: 4px;
        height: 28px;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
        display: none;
    }

    .react-autosuggest__suggestions-container--open {
        margin-top: 0.5rem;
        display: block;
        position: absolute;
        width: 100%;
        border: 1px solid #aaa;
        background-color: #fff;
        font-weight: 300;
        font-size: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 0.5rem 1rem;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: #ddd;
    }
`;
