export const getGapValue = (value: string | number, isNegative = false) => {
    if (typeof value === 'number') {
        return isNegative ? -value : value;
    }
    if (typeof value === 'string') {
        return isNegative
            ? `-${value}${value.includes('px') ? '' : 'px'}`
            : `${value}${value.includes('px') ? '' : 'px'}`;

    }
};
