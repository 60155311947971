import { cacheLatest, deleteAllData } from "../api/jobs";
import { Box, Button, Flex, H6, Paragraph } from "@equalitycheck/eq-core";

const Jobs = () => {
    const deleteAll = async () => {
        if (window.confirm("Are you sure you want to delete all data?")) {
            await deleteAllData();
        }
    };

    return (
        <Flex flexDirection="column">
            <JobItem
                description="Update score cache for current month"
                title="Update latest cache"
            >
                <Button variant="secondary" onClick={cacheLatest}>
                    Update
                </Button>
            </JobItem>
            <JobItem description="Delete all data" title="Delete all data">
                <Button variant="secondary" onClick={deleteAll}>
                    Delete all data
                </Button>
            </JobItem>
        </Flex>
    );
};

interface JobItemProps {
    title: string;
    description: string;
}

const JobItem: React.FunctionComponent<JobItemProps> = ({
    title,
    description,
    children,
}) => {
    return (
        <Box mb={7}>
            <H6 mb={4}>{title}</H6>
            <Paragraph mb={3}>{description}</Paragraph>
            {children}
        </Box>
    );
};

export default Jobs;
