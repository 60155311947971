import {
    GET_INDUSTRIES_FAILED,
    GET_INDUSTRIES_SUCCESS,
    IInddustriesState,
    IndustryStateActionTypes,
} from "./types";

const initialState: IInddustriesState = {
    industries: {},
    isLoading: true,
};

export const industryReducer = (
    state = initialState,
    action: IndustryStateActionTypes
): IInddustriesState => {
    switch (action.type) {
        case GET_INDUSTRIES_SUCCESS:
            return {
                industries: action.industries,
                isLoading: false,
            };
        case GET_INDUSTRIES_FAILED:
            return {
                industries: {},
                isLoading: true,
            };
        default:
            return state;
    }
};
