import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import {
    LayoutProps,
    PositionProps,
    SpaceProps,
    SxProp,
    TypographyProps,
} from '../../types';
import Box from 'components/Box';
import Flex from 'components/Flex';
import Caption from 'components/Caption';

interface IStyledMenu extends SxProp {}
const StyledMenu = styled(Box.withComponent('ul'))<IStyledMenu>(
    ({ theme }) => css({ listStyle: 'none', p: 0 })(theme),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

interface IStyledMenuItem extends SxProp {
    current?: string;
    disabled?: boolean;
}
const StyledMenuItem = styled(Flex.withComponent('li'))<IStyledMenuItem>(
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

interface IMenuContext {
    active?: string;
    setActive: (id: string) => void;
}
const MenuContext = React.createContext<IMenuContext>({
    active: '',
    setActive: () => {},
});

export function useMenu() {
    const context = React.useContext(MenuContext);

    if (context === undefined) {
        throw new Error('useMenu must be used within a <Menu />');
    }

    return context;
}

export interface IMenu
    extends SxProp,
        SpaceProps,
        TypographyProps,
        LayoutProps,
        PositionProps {
    children: JSX.Element[] | JSX.Element;
    current?: string;
    title: string;
}
const Menu = ({ children, current = '', title, ...props }: IMenu) => {
    const [active, setActive] = React.useState(current);

    const context = React.useMemo(
        () => ({
            active,
            setActive: (link: string) => setActive(link),
        }),
        [active, setActive],
    );

    React.useEffect(() => {
        setActive(current);
    }, [current])

    return (
        <MenuContext.Provider value={context}>
            <Box p={5} {...props}>
                <Caption textTransform="uppercase">{title}</Caption>
                <StyledMenu mt={4}>
                    {React.Children.map(children, (child) => (
                        <StyledMenuItem>{child}</StyledMenuItem>
                    ))}
                </StyledMenu>
            </Box>
        </MenuContext.Provider>
    );
};

export default Menu;
