import { compose, Config, system } from '@styled-system/core';
import getWholeValue from 'styles/utils/getWholeValue';

const config: Config = {
    margin: {
        property: 'margin',
        scale: 'space',
        transform: getWholeValue,
    },
    marginTop: {
        property: 'marginTop',
        scale: 'space',
        transform: getWholeValue,
    },
    marginRight: {
        property: 'marginRight',
        scale: 'space',
        transform: getWholeValue,
    },
    marginBottom: {
        property: 'marginBottom',
        scale: 'space',
        transform: getWholeValue,
    },
    marginLeft: {
        property: 'marginLeft',
        scale: 'space',
        transform: getWholeValue,
    },
    marginX: {
        properties: ['marginRight', 'marginLeft'],
        scale: 'space',
        transform: getWholeValue,
    },
    marginY: {
        properties: ['marginTop', 'marginBottom'],
        scale: 'space',
        transform: getWholeValue,
    },
};
config.m = config.margin;
config.mt = config.marginTop;
config.mr = config.marginRight;
config.mb = config.marginBottom;
config.ml = config.marginLeft;
config.mx = config.marginX;
config.my = config.marginY;

const margin = system(config);
const marginProps = compose(margin).propNames;

export { config, margin, marginProps };
