import useSWR from "swr";
import { fetcher } from ".";

export const useCountries = () => {
    const { data, revalidate } = useSWR<{ countryCode: string }[]>(
        `api/admin/countries`,
        fetcher
    );

    return {
        countries: data,
        revalidate,
    };
};

type Gender = {
    percentage: string;
};

type Sexuality = {
    percentage: string;
};

type Ethnicity = {
    percentage: string;
};

type Review = {
    genders: {
        male: Gender;
        female: Gender;
        other: Gender;
    };
    sexualities: {
        heterosexual: Sexuality;
        lgbtqi: Sexuality;
    };
    ethnicities: {
        majorities: Ethnicity;
        minorities: Ethnicity;
    };
};

export type Score = {
    opportunities: string;
    culture: string;
    bias: string;
    sexualAttention: string;
    workLife: string;
    communicatesDiversity: string;
    prioritizesDiversity: string;
    companyFuture: string;
    parentalLeave: string;
    rating: string;
    jokesRemarksMinorityPartOf: string;
    jokesRemarksMinorityNotPartOf: string;
    questionAbilitiesIdentity: string;
    average: string;
};

type Response = {
    reviews: Review;
    scores: {
        general: {
            all: Score;
        };
        genders: {
            male: Score;
            female: Score;
            other: Score;
        };
        sexualities: {
            heterosexual: Score;
            lgbtqi: Score;
        };
        ethnicities: {
            majorities: Score;
            minorities: Score;
        };
    };
    reviewCount: string;
    companyCount: number;
};

export const useCountry = (countryCode: string) => {
    const { data, revalidate } = useSWR<Response>(
        `api/admin/countries/${countryCode}`,
        fetcher
    );

    return {
        country: data,
        revalidate,
    };
};
