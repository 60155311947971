import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    fontFamily: {
        property: 'fontFamily',
        scale: 'fonts',
    },
};
const fontFamily = system(config);
const fontFamilyProps = compose(fontFamily).propNames;

export { config, fontFamily, fontFamilyProps };
