import styled from '@emotion/styled';
import Heading, { IHeading } from '../Heading';

export type IH1 = IHeading;

const H1 = styled(Heading)<IH1>();

H1.displayName = 'H1';
H1.defaultProps = {
    as: 'h1',
    color: 'black',
    clamp: false,
    variant: 'h1',
};

export default H1;
