import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import { PositionProps, SpaceProps, SxProp } from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { position, positionProps } from 'styles/collections/position';
import {
    sizes as avatarSizeStyles,
    variants as avatarVariantStyles,
} from './Avatar.styles';
import Text from 'components/Text';

export const avatarProps = createShouldForwardProp([
    ...(spaceProps as string[]),
    ...(positionProps as string[]),
    'src',
]);

export interface IAvatar extends SxProp, SpaceProps, PositionProps {
    label?: string;
    size?: 'small' | 'base' | 'large';
    src?: string;
    alt?: string;
    variant?:
        | 'purpleFire'
        | 'orangeHaze'
        | 'iceQueen'
        | 'redDelight'
        | 'limonade';
}

interface IContainer extends SxProp {
    size?: 'small' | 'base' | 'large';
    src?: string;
    variant: string;
}
const Container = styled('div', {
    shouldForwardProp: avatarProps,
})<IContainer>(
    ({ src, theme }) =>
        css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxSizing: 'border-box',
            boxShadow: src ? '0 0 4px 0 rgba(0, 0, 0, 0.16)' : 'none',
            borderRadius: 'circle',
            overflow: 'hidden',
        })(theme),
    compose(avatarSizeStyles, avatarVariantStyles, space, position),
    ({ sx, theme }) => css(sx)(theme),
);

interface IImage {
    src: string;
    alt: string;
}
const Image = styled('img')<IImage>(({ theme }) =>
    css({
        display: 'inline-block',
        width: 'full',
        height: 'full',
        minWidth: 'full',
        objectFit: 'cover',
    })(theme),
);

const Avatar = ({ label, size, src, alt, variant }: IAvatar) => (
    <Container src={src} variant={variant!} size={size!}>
        {src ? <Image src={src} alt={alt!} /> : null}
        {!src && label ? (
            <Text as="span" fontWeight="bold">
                {label}
            </Text>
        ) : null}
    </Container>
);
Avatar.displayName = 'Avatar';
Avatar.defaultProps = {
    alt: '',
    size: 'base',
    variant: 'purpleFire',
};

export default Avatar;
