export const GET_INDUSTRIES_SUCCESS = "GET_INDUSTRIES_SUCCESS";
export const GET_INDUSTRIES_FAILED = "GET_INDUSTRIES_FAILED";

export interface IIndustries {
    [key: number]: string;
}

export interface IInddustriesState {
    isLoading: boolean;
    industries: IIndustries;
}

interface IGetIndustriesSuccessAction {
    type: typeof GET_INDUSTRIES_SUCCESS;
    industries: IIndustries;
}

interface IGetIndustriesFailedAction {
    type: typeof GET_INDUSTRIES_FAILED;
}

export type IndustryStateActionTypes =
    | IGetIndustriesSuccessAction
    | IGetIndustriesFailedAction;
