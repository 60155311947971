import styled from '@emotion/styled';
import Heading, { IHeading } from '../Heading';

export type IH6 = IHeading;

const H6 = styled(Heading)<IH6>();

H6.displayName = 'H6';
H6.defaultProps = {
    as: 'h6',
    color: 'black',
    clamp: false,
    variant: 'h6',
};

export default H6;
