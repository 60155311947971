import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    right: {
        property: 'right',
        scale: 'space',
    },
};
const right = system(config);
const rightProps = compose(right).propNames;

export { config, right, rightProps };
