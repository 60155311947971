import variant from '@styled-system/variant';

export const variants = variant({
    variants: {
        warning: {
            bg: 'semantic.warning.secondary',
            borderColor: 'semantic.warning.primary',
            svg: {
                alignSelf: 'baseline',
                color: 'semantic.warning.primary',
                minWidth: 5,
                width: 5,
                height: 5,
            },
        },
        error: {
            bg: 'semantic.error.secondary',
            borderColor: 'semantic.error.primary',
            svg: {
                alignSelf: 'baseline',
                color: 'semantic.error.primary',
                minWidth: 5,
                width: 5,
                height: 5,
            },
        },
        success: {
            bg: 'semantic.success.secondary',
            borderColor: 'semantic.success.primary',
            svg: {
                alignSelf: 'baseline',
                color: 'semantic.success.primary',
                minWidth: 5,
                width: 5,
                height: 5,
            },
        },
        passive: {
            bg: 'semantic.passive.secondary',
            borderColor: 'semantic.passive.primary',
            svg: {
                alignSelf: 'baseline',
                color: 'semantic.passive.primary',
                minWidth: 5,
                width: 5,
                height: 5,
            },
        },
    },
});
