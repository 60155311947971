import { LOCATION_CHANGE } from "connected-react-router";
import {
    BannerStateActionTypes,
    CLEAR_BANNER,
    IBannerState,
    SHOW_ERROR_BANNER,
    SHOW_SUCCESS_BANNER,
} from "./types";

const initialState: IBannerState = {};

export const bannerReducer = (
    state = initialState,
    action: BannerStateActionTypes
): IBannerState => {
    switch (action.type) {
        case SHOW_ERROR_BANNER:
            return {
                current: {
                    message: action.message,
                    type: "error",
                },
            };
        case SHOW_SUCCESS_BANNER:
            return {
                current: {
                    message: action.message,
                    type: "success",
                },
            };
        case LOCATION_CHANGE:
        case CLEAR_BANNER:
            return {};
        default:
            return state;
    }
};
