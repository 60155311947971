// my-theme.ts
import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
    backgroundColors: {
        complementary: "#4b2461",
        contrast: "#e73b66",
        main: "#fae9df",
        pink: "#eac7d0",
        white: "#ffffff",
    },
    banner: {
        error: "rgba(231, 59, 102, 0.4)",
        success: "rgba(40, 202, 66, 0.4)",
        info: "#FFEDAC",
    },
    borderRadius: "5px",
    borderColor: "#E9E9E9",
    buttonColors: {
        danger: "#B63131",
        main: "#4b2461",
        white: "#ffffff",
    },
    card: {
        border: "#E2E2E2",
    },
    fontColors: {
        black: "#000000",
        complementary: "#fae9df",
        contrast: "#e73b66",
        danger: "#B63131",
        main: "#4b2461",
        white: "#ffffff",
        blue: "#4285F4",
    },
    iconColors: {
        selected: "#e83c98",
    },
};
