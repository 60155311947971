import variant from '@styled-system/variant';

export const labelVariants = variant({
    variants: {
        outline: {
            py: 3,
            px: 4,
            borderRadius: 'circle',
            boxShadow: 'field.default',
            border: '2px solid',
            borderColor: 'border',
            '&[aria-disabled="false"]:hover': {
                bg: 'brand.primary',
                borderColor: 'brand.primary',
                boxShadow: 'none',
                span: {
                    color: 'white',
                },
            },
            '&[data-error="true"]': {
                borderColor: 'semantic.error.primary',
            },
            '&[aria-disabled="true"]': {
                bg: 'border',
                borderColor: 'disabled',
                boxShadow: 'none',
                color: 'disabled',
                cursor: 'not-allowed',
            },
        },
    },
});

export const inputVariants = variant({
    variants: {
        base: {
            borderColor: 'placeholder',
            ':not(:disabled):hover': {
                borderColor: 'brand.primary',
                boxShadow: 'field.hover',
            },
            ':not(:disabled):focus': {
                borderColor: 'brand.secondary',
            },
            ':not(:disabled):active': {
                borderColor: 'brand.primary',
            },
            ':not(:disabled):checked': {
                borderColor: 'black',
                ':before': {
                    content: '""',
                    position: 'absolute',
                    opacity: 1,
                    width: 3,
                    height: 3,
                    borderRadius: 'circle',
                    bg: 'black',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                },
                ':hover, :focus, :active': {
                    borderColor: 'brand.primary',
                },
                ':focus:before': {
                    bg: 'brand.primary',
                },
            },
            '&[data-error="true"]': {
                borderColor: 'semantic.error.primary',
            },
            ':disabled': {
                borderColor: 'disabled',
            },
        },
        outline: {
            ':not(:disabled):hover': {
                bg: 'brand.primary',
                borderColor: 'white',
                '&:checked:before': {
                    bg: 'white',
                },
            },
            ':not(:disabled):focus': {
                borderColor: 'black',
            },
            ':not(:disabled):active': {
                borderColor: 'white',
            },
            ':not(:disabled):checked': {
                ':before': {
                    content: '""',
                    position: 'absolute',
                    opacity: 1,
                    width: 3,
                    height: 3,
                    borderRadius: 'circle',
                    bg: 'black',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                },
                ':focus': {
                    ':before': {
                        bg: 'black',
                    },
                },
            },
            ':disabled': {
                bg: 'border',
                borderColor: 'disabled',
            },
        },
    },
});
