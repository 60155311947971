import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import {
    ColorsProps,
    FlexGridItemProps,
    LayoutProps,
    OffsetProps,
    PositionProps,
    SpaceProps,
    SxProp,
} from '../../types';
import { offset, offsetProps } from 'styles/flexbox/offset';
import { layout, layoutProps } from 'styles/collections/layout';
import { colors, colorsProps } from 'styles/collections/colors';
import { space, spaceProps } from 'styles/collections/space';
import { flexbox, flexboxProps } from 'styles/collections/flexbox';
import { position, positionProps } from 'styles/collections/position';
import { getItemOffsetValue, getItemWidthValue } from 'utils/index';

export const flexGridItemProps = createShouldForwardProp([
    ...(flexboxProps as string[]),
    ...(offsetProps as string[]),
    ...(colorsProps as string[]),
    ...(layoutProps as string[]),
    ...(spaceProps as string[]),
    ...(positionProps as string[]),
    'order',
    'width',
]);

export type IFlexGridItem = SxProp &
    FlexGridItemProps &
    OffsetProps &
    ColorsProps &
    LayoutProps &
    SpaceProps &
    PositionProps;

const FlexGridItem = styled('div', {
    shouldForwardProp: flexGridItemProps,
})<IFlexGridItem>(
    ({ theme, offset, order, width }) =>
        css({
            boxSizing: 'border-box',
            flex: '0 0 auto',
            order: order,
            ml: `${getItemOffsetValue(offset!)}%`,
            ...getItemWidthValue(width!),
        })(theme),
    compose(flexbox, offset, colors, layout, space, position),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

FlexGridItem.displayName = 'FlexGridItem';
FlexGridItem.defaultProps = {
    order: 0,
};

export default FlexGridItem;
