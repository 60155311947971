import { useState } from "react";
import {
    IInjectedModalContentProps,
    withModal,
} from "../../components/shared/structure/modal";
import { addUser } from "../../api/company";

import { useForm } from "react-hook-form";
import { Loading } from "../../components/shared/animations/loading";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import { ISearchUser } from "../../api/interfaces/user";
import { useUsers } from "../../api/hooks/useUsers";
import CardLayout from "../../components/shared/CardLayout";
import { LinkButton } from "../../components/shared/actions/buttons";
import { Box, Flex, FormField, Input, Button } from "@equalitycheck/eq-core";

interface IModalContent {
    companyId: string;
    onSuccess: () => void;
}

const ModalContent = ({
    closeModal,
    companyId,
    onSuccess,
}: IModalContent & IInjectedModalContentProps) => {
    // const [loading, setLoading] = useState(false);

    const onDeleteClick = async (userId: string) => {
        await addUser(companyId, userId);
        onSuccess();
        closeModal();
    };

    return <Users onDeleteClick={onDeleteClick} />;
};

type UserSearchFormData = {
    firstName: string;
    lastName: string;
    email: string;
};

const Users = ({
    onDeleteClick,
}: {
    onDeleteClick: (userId: string) => void;
}) => {
    const [search, setSearch] = useState<UserSearchFormData>({
        email: "",
        firstName: "",
        lastName: "",
    });

    const { register, handleSubmit } = useForm<UserSearchFormData>();

    const onSubmit = handleSubmit(async (data) => {
        try {
            setSearch(data);
        } catch (e) {
            console.error(e);
        }
    });

    return (
        <CardLayout title="Users">
            <Flex flexDirection="column">
                <Box as="form" onSubmit={onSubmit}>
                    <Flex justifyContent="space-between">
                        <FormField
                            as={Input}
                            width="100%"
                            label="First name"
                            {...register("firstName")}
                            id="firstName"
                            name="firstName"
                            mr={4}
                            mt={3}
                        />
                        <FormField
                            as={Input}
                            width="100%"
                            label="Last name"
                            {...register("lastName")}
                            id="lastName"
                            name="lastName"
                            mr={4}
                            mt={3}
                        />
                        <FormField
                            width="100%"
                            as={Input}
                            label="Email"
                            {...register("email")}
                            id="email"
                            name="email"
                            mt={3}
                        />
                    </Flex>
                    <Button mt={4} type="submit">
                        Search
                    </Button>
                </Box>
                <Box>
                    <SearchResults
                        onDeleteClick={onDeleteClick}
                        search={search}
                    />
                </Box>
            </Flex>
        </CardLayout>
    );
};

const SearchResults = ({
    search,
    onDeleteClick,
}: {
    search: UserSearchFormData;
    onDeleteClick: (userId: string) => void;
}) => {
    const { users, revalidate } = useUsers(
        search.email,
        search.firstName,
        search.lastName
    );

    const onDelete = (userId: string) => {
        revalidate();
        onDeleteClick(userId);
    };

    if (!users) {
        return <Loading />;
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>First name</TableCell>
                    <TableCell>Last name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {users.map((u, i) => (
                    <User key={i} user={u} onDeleteClick={onDelete} />
                ))}
            </TableBody>
        </Table>
    );
};

const User = ({
    user,
    onDeleteClick,
}: {
    user: ISearchUser;
    onDeleteClick: (userId: string) => void;
}) => {
    return (
        <TableRow>
            <TableCell>{user.firstName}</TableCell>
            <TableCell>{user.lastName}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>
                <Flex>
                    <LinkButton onClick={() => onDeleteClick(user.id)}>
                        Add
                    </LinkButton>
                </Flex>
            </TableCell>
        </TableRow>
    );
};

const Modal = withModal(ModalContent);

export default Modal;
