import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import {
    ColorsProps,
    LayoutProps,
    PositionProps,
    SpaceProps,
    SxProp,
} from '../../types';
import { colors, colorsProps } from 'styles/collections/colors';
import { layout, layoutProps } from 'styles/collections/layout';
import { space, spaceProps } from 'styles/collections/space';
import { flexbox, flexboxProps } from 'styles/collections/flexbox';
import { position, positionProps } from 'styles/collections/position';
import {
    spaces as cardSpaceStyles,
    variants as cardVariantStyles,
} from './Card.styles';

export const cardProps = createShouldForwardProp([
    ...(colorsProps as string[]),
    ...(layoutProps as string[]),
    ...(spaceProps as string[]),
    ...(flexboxProps as string[]),
    ...(positionProps as string[]),
]);

export interface ICard
    extends SxProp,
        ColorsProps,
        LayoutProps,
        SpaceProps,
        PositionProps {
    space?:
        | 'small'
        | 'small.large'
        | 'small.none'
        | 'large'
        | 'large.small'
        | 'large.none'
        | 'none'
        | 'none.small'
        | 'none.large';
    variant?:
        | 'light'
        | 'base'
        | 'heavy'
        | 'shadow'
        | 'shadow.light'
        | 'shadow.heavy'
        | 'none';
}

const Card = styled('div', {
    shouldForwardProp: cardProps,
})<ICard>(
    ({ theme }) =>
        css({
            appearance: 'none',
            borderRadius: 'large',
            overflow: 'hidden',
            transitionProperty:
                'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
            transitionDuration: '150ms',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        })(theme),
    compose(
        cardSpaceStyles,
        cardVariantStyles,
        colors,
        layout,
        space,
        flexbox,
        position,
    ),
    ({ sx, theme }) => css(sx)(theme),
);

Card.displayName = 'Card';
Card.defaultProps = {
    space: 'large',
    variant: 'light',
};

export default Card;
