import { useParams } from "react-router";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";

import { FlexGrid, FlexGridItem } from "@equalitycheck/eq-core";
import { ScoreRow } from "pages/country/country";
import { useIndustry } from "api/hooks/useIndustries";
import { useSelector } from "react-redux";
import { AppState } from "store";

const Industry = () => {
    const { id, industryId } = useParams<{ id: string; industryId: string }>();
    const { industry } = useIndustry(id, industryId);

    const industries = useSelector(
        (state: AppState) => state.industryReducer.industries
    );

    if (!industry) {
        return null;
    }

    return (
        <>
            <FlexGrid>
                <FlexGridItem>Country</FlexGridItem>
                <FlexGridItem>{id}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Industry</FlexGridItem>
                <FlexGridItem>{industries[industryId]}</FlexGridItem>
            </FlexGrid>

            <FlexGrid>
                <FlexGridItem>Number of reviews</FlexGridItem>
                <FlexGridItem>{industry.numberOfReviews}</FlexGridItem>
            </FlexGrid>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>opportunities</TableCell>
                        <TableCell>culture</TableCell>
                        <TableCell>bias</TableCell>
                        <TableCell>sexualAttention</TableCell>
                        <TableCell>workLife</TableCell>
                        <TableCell>communicatesDiversity</TableCell>
                        <TableCell>prioritizesDiversity</TableCell>
                        <TableCell>companyFuture</TableCell>
                        <TableCell>parentalLeave</TableCell>
                        <TableCell>rating</TableCell>
                        <TableCell>jokesRemarksMinorityPartOf</TableCell>
                        <TableCell>jokesRemarksMinorityNotPartOf</TableCell>
                        <TableCell>questionAbilitiesIdentity</TableCell>
                        <TableCell>average</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <ScoreRow title="All" score={industry.scores.general.all} />
                    <ScoreRow
                        title="Majorities"
                        score={industry.scores.ethnicities.majorities}
                    />
                    <ScoreRow
                        title="Minorities"
                        score={industry.scores.ethnicities.minorities}
                    />
                    <ScoreRow
                        title="Female"
                        score={industry.scores.genders.female}
                    />
                    <ScoreRow
                        title="Male"
                        score={industry.scores.genders.male}
                    />
                    <ScoreRow
                        title="Other"
                        score={industry.scores.genders.other}
                    />
                    <ScoreRow
                        title="Heterosexual"
                        score={industry.scores.sexualities.heterosexual}
                    />
                    <ScoreRow
                        title="Lgbtqi"
                        score={industry.scores.sexualities.lgbtqi}
                    />
                </TableBody>
            </Table>
        </>
    );
};

export default Industry;
