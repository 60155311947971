import variant from '@styled-system/variant';

export const base = {
    appearance: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'body',
    fontWeight: 'bold',
    borderRadius: 'circle',
    border: 0,
    outline: 0,
    cursor: 'pointer',
    transitionProperty:
        'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '150ms',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    ':disabled': { bg: 'disabled', color: 'white', cursor: 'not-allowed' },
};

export const sizes = variant({
    prop: 'size',
    variants: {
        small: {
            px: 4,
            py: 3,
            fontSize: 'caption',
            lineHeight: 'caption',
        },
        base: {
            px: 5,
            py: 3,
            fontSize: 'small',
            lineHeight: 'paragraph',
        },
        large: {
            px: 6,
            py: '12px',
            fontSize: 'paragraph',
            lineHeight: 'paragraph',
        },
    },
});

export const variants = variant({
    variants: {
        primary: {
            bg: 'brand.primary',
            color: 'white',
            ':not(:disabled):hover': {
                bg: 'brand.hover',
            },
            ':not(:disabled):focus': {
                bg: 'brand.primary',
                boxShadow: 'inset 0 0px 0px 2px rgb(255 194 191)',
            },
            ':not(:disabled):active': {
                bg: 'brand.active',
            },
        },
        secondary: {
            bg: 'white',
            color: 'black',
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.16)',
            ':not(:disabled):hover': {
                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.24)',
                color: 'brand.primary',
            },
            ':not(:disabled):focus': {
                boxShadow:
                    'inset 0 0px 0px 2px rgb(255 194 191), 0 2px 16px 0 rgba(0, 0, 0, 0.24)',
                color: 'brand.primary',
            },
            ':not(:disabled):active': {
                boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.16)',
                color: 'brand.active',
            },
        },
        tertiary: {
            bg: 'transparent',
            color: 'brand.primary',
            ':not(:disabled):hover': {
                color: 'brand.hover',
            },
            ':not(:disabled):focus': {
                bg: 'brand.secondary',
                color: 'brand.primary',
            },
            ':not(:disabled):active': {
                color: 'brand.active',
            },
            ':disabled': {
                bg: 'transparent',
                color: 'disabled',
            },
        },
        success: {
            bg: 'semantic.success.primary',
            color: 'white',
        },
    },
});
