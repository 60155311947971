import { IUser } from "../../api/interfaces/user";
import { H2 } from "../shared/text";
import { LoginForm } from "./loginForm";
import { OAuth } from "./oauth";
import { AuthContainer, Flex } from "../../components/shared/structure";
import { loginSuccessAction } from "../../store/user/actions";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const providers = ["google"];

export const LoginPage = () => {
    const dispatch = useDispatch();

    const onAuthSuccess = () => {
        dispatch(push(`/`));
    };

    return <Login onSuccess={onAuthSuccess} />;
};

interface ILoginProps {
    onSuccess?: (user: IUser) => void;
}

export const Login = (props: ILoginProps) => {
    const dispatch = useDispatch();

    const onAuthSuccess = (user: IUser) => {
        dispatch(loginSuccessAction(user));
        if (props.onSuccess) {
            props.onSuccess(user);
        }
    };

    if (process.env.NODE_ENV === "production") {
        return (
            <AuthContainer>
                <Flex alginItems="center">
                    <H2>Welcome</H2>
                </Flex>
                {providers.map((provider) => (
                    <OAuth
                        onSuccess={onAuthSuccess}
                        type="login"
                        key={provider}
                        provider={provider}
                    />
                ))}
            </AuthContainer>
        );
    }

    return (
        <AuthContainer>
            <Flex alginItems="center">
                <H2>Welcome</H2>
            </Flex>
            <LoginForm onSuccess={onAuthSuccess} />
        </AuthContainer>
    );
};
