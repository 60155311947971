import { FunctionComponent } from "react";
import styled from "styled-components";
import { IValidationError } from "../../../store/interfaces";
import { Banner } from "../banner";

interface IErrorMessageProps {
    className?: string;
    color?: string;
    id: string;
}

const UnstyledErrorMessage: FunctionComponent<IErrorMessageProps> = ({
    children,
    className,
    id,
}) => {
    return (
        <div id={id} role="alert" className={className}>
            {children}
        </div>
    );
};

export const ErrorMessage = styled(UnstyledErrorMessage)`
    color: ${(props) => props.color || props.theme.fontColors.main};
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
`;

const ErrorBannerContainer = styled.div`
    width: 100%;
`;

interface IValidationErrorProps {
    errors: IValidationError[];
    param: string;
    color?: string;
    id: string;
}

export const ValidationError = ({
    errors,
    param,
    color,
    id,
}: IValidationErrorProps) => {
    const error = errors.filter((e) => e.param === param);
    if (!error || error.length === 0) {
        return null;
    }

    return (
        <ErrorBannerContainer>
            <Banner type="error" marginTop={1}>
                <ErrorMessage id={id} color={color}>
                    <ul>
                        {error.map((e) => (
                            <li key={e.param + errors.indexOf(e)}>
                                {e.msg}
                                <br />
                            </li>
                        ))}
                    </ul>
                </ErrorMessage>
            </Banner>
        </ErrorBannerContainer>
    );
};
