import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    maxWidth: {
        property: 'maxWidth',
        scale: 'sizes',
    },
};
const maxWidth = system(config);
const maxWidthProps = compose(maxWidth).propNames;

export { config, maxWidth, maxWidthProps };
