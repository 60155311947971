import styled from "styled-components";
import { FontColor, getFontColor } from "../utils";

interface IFontProps {
    readonly color?: FontColor;
    readonly weight?: "400" | "500" | "700";
    readonly textAlign?: "left" | "center";
    readonly whiteSpace?: "pre-wrap";
}

export const H1 = styled.h1<IFontProps>`
    font-family: "Montserrat", sans-serif;
    min-height: 29px;
    color: ${(props) => getFontColor(props.theme, props.color)};
    font-size: 24px;
    font-weight: 600;
    text-align: ${(props) => props.textAlign || "center"};

    @media (min-width: 600px) {
        min-height: 44px;
        font-size: 36px;
    }

    strong {
        color: ${(props) => props.theme.fontColors.contrast};
    }
`;

export const H2 = styled.h2<IFontProps>`
    font-family: "Montserrat", sans-serif;
    min-height: 22px;
    color: ${(props) => getFontColor(props.theme, props.color)};
    font-size: 18px;
    font-weight: 500;
    text-align: ${(props) => props.textAlign || "center"};

    @media (min-width: 600px) {
        min-height: 29px;
        font-size: 24px;
    }
`;

export const H3 = styled.h3<IFontProps>`
    font-family: "Montserrat", sans-serif;
    min-height: 22px;
    color: ${(props) => getFontColor(props.theme, props.color)};
    text-align: ${(props) => props.textAlign || "initial"};
    font-size: 18px;
    font-weight: 700;
`;

export const H4 = styled.h4<IFontProps>`
    font-family: "Montserrat", sans-serif;
    min-height: 26px;
    color: ${(props) => getFontColor(props.theme, props.color)};
    font-size: 18px;
    font-weight: 700;
    text-align: ${(props) => props.textAlign || "center"};

    @media (min-width: 600px) {
        min-height: 30px;
        font-size: 20px;
    }
`;

export const H5 = styled.h5<IFontProps>`
    margin: 0;
    font-family: "Montserrat", sans-serif;
    min-height: 20px;
    color: ${(props) => getFontColor(props.theme, props.color)};
    font-size: 16px;
    font-weight: 700;
    text-align: ${(props) => props.textAlign || "center"};
`;

export const Text = styled.span<IFontProps>`
    font-family: "Roboto", sans-serif;
    min-height: 20px;
    color: ${(props) => getFontColor(props.theme, props.color)};
    text-align: ${(props) => props.textAlign || "initial"};
    font-size: 16px;
    line-height: 28px;
    font-weight: ${(props) => props.weight || "400"};
    white-space: ${(props) => props.whiteSpace || "initial"};

    @media (min-width: 600px) {
        min-height: 22px;
        font-size: 16px;
    }
`;

export const IconText = styled(Text)`
    display: flex;
    line-height: 24px;

    svg {
        margin-right: 0.5rem;
    }
`;

export const Paragraph = styled.p<IFontProps>`
    margin: 1rem 0;
    font-family: "Roboto", sans-serif;
    min-height: 20px;
    color: ${(props) => getFontColor(props.theme, props.color)};
    text-align: ${(props) => props.textAlign || "initial"};
    font-size: 16px;
    font-weight: ${(props) => props.weight || "400"};

    @media (min-width: 600px) {
        min-height: 22px;
        font-size: 18px;
    }
`;

export const MaxLinesWrapper = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
    max-height: 4.6rem;
`;
