import { useSelector } from "react-redux";

import { Banner } from "./shared/banner";
import { AppState } from "../store";

export const BannerContainer = () => {
    const currentBanner = useSelector(
        (state: AppState) => state.bannerReducer.current
    );

    if (!currentBanner) {
        return null;
    }
    return <Banner type={currentBanner.type}>{currentBanner.message}</Banner>;
};
