export type IndicatorType = typeof indicators[number];
export const indicators = [
    "opportunities",
    "culture",
    "bias",
    "sexualAttention",
    "workLife",
    "communicatesDiversity",
    "prioritizesDiversity",
    "companyFuture",
    "parentalLeave",
    "rating",
    "jokesRemarksMinorityPartOf",
    "jokesRemarksMinorityNotPartOf",
    "questionAbilitiesIdentity",
] as const;
