import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    maxHeight: {
        property: 'maxHeight',
        scale: 'sizes',
    },
};
const maxHeight = system(config);
const maxHeightProps = compose(maxHeight).propNames;

export { config, maxHeight, maxHeightProps };
