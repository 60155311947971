import { get } from '@styled-system/core';
import isNumber from './isNumber';

export default function getWidth(value: any, scale: any) {
    return get(
        scale,
        value,
        !isNumber(value) || value > 1 ? value : `${value * 100}%`,
    );
}
