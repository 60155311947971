import MaterialTable from "@material-ui/core/Table";
import MaterialTableBody from "@material-ui/core/TableBody";
import MaterialTableCell from "@material-ui/core/TableCell";
import MaterialTableHead from "@material-ui/core/TableHead";
import MaterialTableRow from "@material-ui/core/TableRow";

export const Table = MaterialTable;
export const TableHead = MaterialTableHead;
export const TableRow = MaterialTableRow;
export const TableCell = MaterialTableCell;
export const TableBody = MaterialTableBody;
