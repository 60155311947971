import { Dispatch } from "redux";

import { getCountries } from "../../api/country";
import { GET_COUNTRIES_FAILED, GET_COUNTRIES_SUCCESS } from "./types";

export const getCountriesAction = () => async (dispatch: Dispatch) => {
    try {
        const response = await getCountries();
        const countries = await response.data;
        dispatch({ type: GET_COUNTRIES_SUCCESS, countries });
    } catch (e) {
        dispatch({ type: GET_COUNTRIES_FAILED });
    }
};
