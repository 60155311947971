import styled from "styled-components";

export const Form = styled.form``;

export const FormRow = styled.div`
    display: flex;
    padding: 0.5rem 0;
`;

interface ILegendProps {
    width?: string;
}

export const Legend = styled.legend<ILegendProps>`
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.fontColors.main};
    width: ${(props) => props.width || "initial"};
`;

export const Label = styled.label`
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > input,
    > textarea {
        margin-top: 0.5rem;
    }
`;

export const HiddenLabel = styled.label`
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
`;

export const Fieldset = styled.fieldset`
    border: none;
    margin: 0;
    padding: 0;
    padding-top: 1rem;
`;

export const FormGroup = styled.section`
    margin: 1rem 0;
`;

export * from "./select";
export * from "./checkbox";
export * from "./radio";
export * from "./textArea";
export * from "./autosuggest";
