import { compose, Config, system } from '@styled-system/core';
import { config as colorConfig } from 'styles/color';
import { config as backgroundColorConfig } from 'styles/backgroundColor';

const config: Config = {
    ...backgroundColorConfig,
    ...colorConfig,
    opacity: true,
};
const colors = system(config);
const colorsProps = compose(colors).propNames;

export { config, colors, colorsProps };
