import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    backgroundColor: {
        property: 'backgroundColor',
        scale: 'colors',
    },
};
config.bgColor = config.backgroundColor;
config.bg = config.backgroundColor;

const backgroundColor = system(config);
const backgroundColorProps = compose(backgroundColor).propNames;

export { config, backgroundColor, backgroundColorProps };
