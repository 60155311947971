import variant from '@styled-system/variant';

export const base = {
    m: 0,
    p: 0,
    minWidth: 0,
    fontFamily: 'body',
};

export const variants = variant({
    variants: {
        paragraph: {
            fontSize: 'paragraph',
            lineHeight: 'paragraph',
            letterSpacing: 'paragraph',
        },
        small: {
            fontSize: 'small',
            lineHeight: 'small',
            letterSpacing: 'small',
        },
        caption: {
            fontSize: 'caption',
            lineHeight: 'caption',
            letterSpacing: 'caption',
        },
    },
});
