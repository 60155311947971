import Layout from "./layout/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";

import { BannerContainer } from "./bannerContainer";



import { Companies, Company } from "../pages/company";
import { AppState } from "../store";
import { getUserAction } from "../store/user/actions";
import { getCountriesAction } from "../store/countries/actions";
import { getIndustriesAction } from "../store/industries/actions";
import { Statistics, Jobs, Top10, Users } from "../pages";
import { Loading } from "./shared/animations/loading";
import { Countries, Country } from "pages/country";
import { Industry } from "pages/industry";

export const Admin = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserAction());
        dispatch(getCountriesAction());
        dispatch(getIndustriesAction());
    }, [dispatch]);

    const user = useSelector((state: AppState) => state.userReducer.user);
    const isAuthenticated = useSelector(
        (state: AppState) => state.userReducer.isAuthenticated
    );
    const isLoadingUser = useSelector(
        (state: AppState) => state.userReducer.isLoading
    );

    if (isLoadingUser) {
        return <Loading />;
    }

    if (!isAuthenticated || (user && user.role !== "admin")) {
        return <Redirect to={{ pathname: "/login" }} />;
    }

    return (
        <Layout>
            <BannerContainer />
            <Switch>
                <Route path={`/companies/:id`} component={Company} />
                <Route path={`/companies`} component={Companies} />

                <Route
                    path="/countries/:id/industries/:industryId"
                    component={Industry}
                />
                <Route path="/countries/:id" component={Country} />
                <Route path="/countries" component={Countries} />

                <Route path={`/statistics`} component={Statistics} />

                <Route path={`/jobs`} component={Jobs} />
                <Route path={`/top10`} component={Top10} />
                <Route path={`/users`} component={Users} />
                <Route path={``} component={Companies} />
            </Switch>
        </Layout>
    );
};
