import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    letterSpacing: {
        property: 'letterSpacing',
        scale: 'letterSpacings',
    },
};
const letterSpacing = system(config);
const letterSpacingProps = compose(letterSpacing).propNames;

export { config, letterSpacing, letterSpacingProps };
