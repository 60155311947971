import { useParams } from "react-router";
import {
    ButtonGroup,
    SecondaryButton,
} from "../../components/shared/actions/buttons";
import { SecondaryLink } from "../../components/shared/actions/link";
import { Loading } from "../../components/shared/animations/loading";
import { H1 } from "../../components/shared/text";
import { unverifyCompany, verifyCompany } from "../../api/company";
import { ICompanyScore, ICompanyUserClaim } from "../../api/interfaces/company";
import { AppState } from "../../store";
import { useSelector } from "react-redux";
import { useCompany } from "../../api/hooks/useCompany";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import { indicators } from "../../api/types";
import { monthAndYear } from "../../utils/date";

import ResetClaimModal from "../../modules/company/ResetClaimModal";
import RemoveUserModal from "../../modules/company/RemoveUserModal";
import AddUserModal from "../../modules/company/AddUserModal";

import { Flex, FlexGrid, FlexGridItem } from "@equalitycheck/eq-core";

const Company = () => {
    const { id } = useParams<{ id: string }>();
    const { companyResponse, isLoading, mutate } = useCompany(id);
    const industries = useSelector(
        (state: AppState) => state.industryReducer.industries
    );

    const unverify = (id: string) => async () => {
        if (companyResponse) {
            await unverifyCompany(id);
            mutate({
                ...companyResponse,
                company: {
                    ...companyResponse.company,
                    verified: false,
                },
            });
        }
    };

    const verify = (id: string) => async () => {
        if (companyResponse) {
            await verifyCompany(id);
            mutate({
                ...companyResponse,
                company: {
                    ...companyResponse.company,
                    verified: true,
                },
            });
        }
    };

    const revalidate = () => {
        mutate();
    };

    if (isLoading) {
        return <Loading />;
    }

    if (!companyResponse) {
        return null;
    }

    const { company, users, claim, scores } = companyResponse;

    return (
        <>
            <FlexGrid>
                <FlexGridItem>
                    <H1 textAlign="left">Company information</H1>
                </FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Name</FlexGridItem>
                <FlexGridItem>{company.name}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Country</FlexGridItem>
                <FlexGridItem>{company.countryCode}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Description</FlexGridItem>
                <FlexGridItem>{company.companyDescription}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Company Number (VAT)</FlexGridItem>
                <FlexGridItem>{company.companyNumber}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Industry</FlexGridItem>
                <FlexGridItem>
                    {company.industryId && industries[company.industryId]}
                </FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>City</FlexGridItem>
                <FlexGridItem>{company.city}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Verified</FlexGridItem>
                <FlexGridItem>{company.verified ? "Yes" : "No"}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Plan</FlexGridItem>
                <FlexGridItem>{company.plan}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Email</FlexGridItem>
                <FlexGridItem>{company.email}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Logo</FlexGridItem>
                <FlexGridItem>
                    <img alt="" src={company.logo} />
                </FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Website</FlexGridItem>
                <FlexGridItem>{company.website}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Revenue</FlexGridItem>
                <FlexGridItem>{company.revenue}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Number of women</FlexGridItem>
                <FlexGridItem>{company.numberOfWomen}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Number of men</FlexGridItem>
                <FlexGridItem>{company.numberOfMen}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Number of other</FlexGridItem>
                <FlexGridItem>{company.numberOfOther}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Number of employees</FlexGridItem>
                <FlexGridItem>{company.numberOfEmployees}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Gender of Ceo</FlexGridItem>
                <FlexGridItem>{company.genderOfCeo}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>New hires</FlexGridItem>
                <FlexGridItem>{company.newHires}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Left company</FlexGridItem>
                <FlexGridItem>{company.leftCompany}</FlexGridItem>
            </FlexGrid>
            <CompanyClaimInformation claim={claim} />
            <FlexGrid>
                {claim && (
                    <ResetClaimModal
                        onSuccess={revalidate}
                        title="Remove company owner"
                        buttonText="Remove company owner"
                        companyId={company.id}
                    />
                )}
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>
                    <H1 textAlign="left">User information</H1>
                </FlexGridItem>
            </FlexGrid>
            {users.map((u) => (
                <FlexGrid key={u.username}>
                    <FlexGridItem>
                        <Flex alignItems="center">
                            <span>
                                {u.firstName} {u.middleName} {u.lastName} -{" "}
                                {u.email}
                            </span>
                            <RemoveUserModal
                                onSuccess={revalidate}
                                title="Remove"
                                buttonText="Remove"
                                companyId={company.id}
                                userId={u.id}
                                buttonType="link"
                            />
                        </Flex>
                    </FlexGridItem>
                </FlexGrid>
            ))}

            <AddUserModal
                onSuccess={revalidate}
                title="Add user"
                buttonText="Add user"
                companyId={company.id}
            />
            <FlexGrid>
                <FlexGridItem>
                    <H1 textAlign="left">Score information</H1>
                </FlexGridItem>
            </FlexGrid>
            <CompanyScore score={scores} />
            <FlexGrid>
                <ButtonGroup>
                    <SecondaryLink to={`/companies/${id}/edit`}>
                        Edit
                    </SecondaryLink>
                    {company.verified ? (
                        <SecondaryButton onClick={unverify(company.id)}>
                            Unverify
                        </SecondaryButton>
                    ) : (
                        <SecondaryButton onClick={verify(company.id)}>
                            Verify
                        </SecondaryButton>
                    )}
                </ButtonGroup>
            </FlexGrid>
        </>
    );
};

const CompanyScore = ({ score }: { score: ICompanyScore[] }) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Month</TableCell>
                    {indicators.map((indicator) => (
                        <TableCell>{indicator}</TableCell>
                    ))}
                    <TableCell>Total number</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {score.map((score) => (
                    <TableRow>
                        <TableCell>{monthAndYear(score.scoreAt)}</TableCell>
                        {indicators.map((indicator) => (
                            <TableCell>
                                {parseFloat(score[indicator]).toFixed(2)}
                            </TableCell>
                        ))}
                        <TableCell>{score.numberOfReviews}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

const CompanyClaimInformation = ({ claim }: { claim?: ICompanyUserClaim }) => {
    if (!claim) {
        return null;
    }

    return (
        <>
            <FlexGrid>
                <FlexGridItem>
                    <H1 textAlign="left">Claim information</H1>
                </FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Name</FlexGridItem>
                <FlexGridItem>{claim.name}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Position</FlexGridItem>
                <FlexGridItem>{claim.position}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Email</FlexGridItem>
                <FlexGridItem>{claim.email}</FlexGridItem>
            </FlexGrid>
            <FlexGrid>
                <FlexGridItem>Phone</FlexGridItem>
                <FlexGridItem>{claim.telephone}</FlexGridItem>
            </FlexGrid>
        </>
    );
};

export default Company;
