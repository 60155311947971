import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import { ColorsProps, SpaceProps, SxProp, TypographyProps } from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { colors, colorsProps } from 'styles/collections/colors';
import { typography, typographyProps } from 'styles/collections/typography';
import { base } from './Input.styles';

export const inputProps = createShouldForwardProp([
    ...(colorsProps as string[]),
    ...(spaceProps as string[]),
    ...(typographyProps as string[]),
]);

export interface IInput
    extends Omit<React.ComponentPropsWithoutRef<'input'>, 'color'>,
        SxProp,
        ColorsProps,
        SpaceProps,
        TypographyProps {
    error?: boolean;
    ref?: React.ForwardedRef<HTMLInputElement>;
}

const Input = styled('input', {
    shouldForwardProp: inputProps,
})<IInput>(
    ({ error, theme }) =>
        css({
            ...base,
            borderColor: error ? 'semantic.error.primary' : 'border',
        })(theme),
    compose(colors, space, typography),
    ({ sx, theme }) => css(sx)(theme),
);
Input.displayName = 'Input';
Input.defaultProps = {
    disabled: false,
    error: false,
};

type IReadOnlyInput = Omit<React.ComponentPropsWithoutRef<'input'>, 'color'> &
    SxProp &
    ColorsProps &
    SpaceProps &
    TypographyProps;

const ReadOnlyInput: React.ComponentType<IReadOnlyInput> = styled('input', {
    shouldForwardProp: inputProps,
})(
    ({ theme }) =>
        css({
            appearance: 'none',
            display: 'block',
            boxSizing: 'border-box',
            m: 0,
            p: 3,
            minWidth: 0,
            width: 'full',
            bg: 'transparent',
            color: 'placeholder',
            border: 0,
            borderRadius: 'field',
            fontFamily: 'body',
            fontSize: 'small',
            lineHeight: 'small',
            letterSpacing: 'small',
            outline: 0,
        })(theme),
    compose(colors, space, typography),
    ({ sx, theme }) => css(sx)(theme),
);
ReadOnlyInput.defaultProps = {
    readOnly: true,
};

export { ReadOnlyInput };
export default Input;
