import { useState } from "react";
import {
    ButtonGroup,
    PrimaryButton,
    SecondaryButton,
} from "../../components/shared/actions/buttons";
import { Form } from "../../components/shared/form";
import {
    IInjectedModalContentProps,
    withModal,
} from "../../components/shared/structure/modal";
import { Paragraph } from "../../components/shared/text";
import { removeUser } from "../../api/company";

interface IModalContent {
    companyId: string;
    userId: string;
    onSuccess: () => void;
}

const ModalContent = ({
    closeModal,
    companyId,
    userId,
    onSuccess,
}: IModalContent & IInjectedModalContentProps) => {
    const [loading, setLoading] = useState(false);

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        await removeUser(companyId, userId);
        onSuccess();
        closeModal();
    };

    return (
        <Form onSubmit={onSubmit}>
            <Paragraph>Are you sure?</Paragraph>
            <ButtonGroup>
                <SecondaryButton onClick={closeModal}>No</SecondaryButton>
                <PrimaryButton
                    disabled={loading}
                    type="submit"
                    background="danger"
                >
                    Yes
                </PrimaryButton>
            </ButtonGroup>
        </Form>
    );
};

const Modal = withModal(ModalContent);

export default Modal;
