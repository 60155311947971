import { DefaultTheme } from "styled-components";

export type FontColor =
    | "main"
    | "complementary"
    | "contrast"
    | "white"
    | "inherit"
    | "danger";
export type BackgroundColor =
    | "main"
    | "complementary"
    | "contrast"
    | "white"
    | "inherit"
    | "pink";
export type Size = "m" | "l" | "xl";

export const getHoverColor = (
    theme: DefaultTheme,
    color: FontColor = "main"
) => {
    if (color === "inherit") {
        return "inherit";
    }
    const hoverColor = {
        danger: "red",
        complementary: "",
        contrast: "",
        main: "contrast",
        white: "black",
    }[color];

    return theme.fontColors[hoverColor];
};

export const getFontColor = (
    theme: DefaultTheme,
    color: FontColor = "main"
) => {
    if (color === "inherit") {
        return "inherit";
    }
    return theme.fontColors[color];
};

export const getBackgroundColor = (
    theme: DefaultTheme,
    color: BackgroundColor = "complementary"
) => {
    if (color === "inherit") {
        return "inherit";
    }
    return theme.backgroundColors[color];
};

export const getButtonSize = (size: Size = "m") => {
    return {
        l: "2rem",
        m: "1rem",
        xl: "4rem",
    }[size];
};
