import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { ColorsProps, SpaceProps, SxProp, TypographyProps } from '../../types';
import { colors, space, typography } from '@/styles/collections';
import { useMenu } from 'components/Menu';

interface IStyledLink extends SxProp, ColorsProps, SpaceProps, TypographyProps {
    active?: string;
    disabled?: boolean;
    id?: string;
}
const StyledLink = styled.a<IStyledLink>(
    compose(space, colors, typography),
    ({ theme, active, disabled, id }) =>
        css({
            display: 'flex',
            m: 0,
            boxSizing: 'border-box',
            width: 'full',
            p: 3,
            outline: 0,
            borderRadius: 8,
            cursor: disabled ? 'not-allowed' : 'pointer',
            textDecoration: 'none',
            bg: active === id ? 'brand.tertiary' : undefined,
            color: active === id ? 'brand.primary' : 'black',
            fontFamily: 'body',
            fontSize: 'small',
            fontWeight: 'bold',
            lineHeight: 'small',
            letterSpacing: 'small',
            '&[aria-disabled="false"]:hover': {
                bg: 'brand.primary',
                color: 'white',
                svg: {
                    color: 'white',
                },
            },
            '&[aria-disabled="false"]:focus': {
                boxShadow: 'inset 0 0 0px 2px #FFC2BF',
            },
            '&[aria-disabled="false"]:active': {
                bg: 'brand.active',
                color: 'white',
            },
            '&[aria-disabled="true"] svg': {
                color: active === id ? 'brand.primary' : 'placeholder',
            },
            '&[aria-disabled="true"]': {
                bg: active === id ? 'border' : undefined,
                color: 'disabled',
                svg: {
                    color: 'disabled',
                },
            },
        })(theme),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

export interface IMenuLink {
    disabled?: boolean;
    href?: string;
    icon?: any;
    id: string;
    children: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    as?: React.ElementType<any>;
}
const MenuLink = React.forwardRef<HTMLAnchorElement, IMenuLink>(({
    disabled = false,
    href,
    icon: Icon,
    id,
    children,
    onClick,
    as="a",
    ...props
}, ref) => {
    const { active, setActive } = useMenu();
    const internalOnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (!disabled) {
            setActive(id)
            onClick?.(event)
        }
    }
    return (
        <StyledLink
            as={as}
            tabIndex={disabled ? -1 : 0}
            href={href}
            ref={ref}
            id={id}
            active={active}
            aria-disabled={disabled ? 'true' : 'false'}
            disabled={disabled}
            onClick={internalOnClick}
            onKeyDown={({ code }) => {
                if (!disabled && ['Enter', 'Space'].includes(code)) {
                    setActive(id);
                }
            }}
            {...props}
        >
            {Icon ? <Icon mr={4} /> : null}
            {children}
        </StyledLink>
    );
});

export default MenuLink;
