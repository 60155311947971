import variant from '@styled-system/variant';

export const sizes = variant({
    prop: 'size',
    variants: {
        small: {
            width: 48,
            height: 48,
            span: {
                fontSize: 'caption',
                lineHeight: 'caption',
                letterSpacing: 'caption',
            },
        },
        base: {
            width: 7,
            height: 7,
            span: {
                fontSize: 'paragraph',
                lineHeight: 'paragraph',
                letterSpacing: 'paragraph',
            },
        },
        large: {
            width: 8,
            height: 8,
            span: {
                fontSize: 'h2',
                lineHeight: 'h2',
                letterSpacing: 'h2',
            },
        },
    },
});

export const variants = variant({
    variants: {
        purpleFire: {
            backgroundImage:
                'linear-gradient(to right, #C031CC, #9C34E9, #622DEF)',
            color: 'white',
        },
        orangeHaze: {
            backgroundImage:
                'linear-gradient(to right, #F0AA42, #E73B66, #F04242)',
            color: 'white',
        },
        iceQueen: {
            backgroundImage:
                'linear-gradient(to right, #24D3DB, #425EF0, #8100E7)',
            color: 'white',
        },
        redDelight: {
            backgroundImage:
                'linear-gradient(to right, #F0AA42, #F04242, #F04242)',
            color: 'white',
        },
        limonade: {
            backgroundImage:
                'linear-gradient(to right, #F3EC32, #B4ED3B, #46B252)',
            color: 'black',
        },
    },
});
