import get from './get';
import isNumber from './isNumber';

export default function getWholeValue(n: any, scale: any) {
    if (!isNumber(n)) return get(scale, n, n);

    const isNegative = n < 0;
    const absValue = Math.abs(n as number);
    const value = get(scale, absValue, absValue) as any;

    return isNumber(value)
        ? value * (isNegative ? -1 : 1)
        : isNegative
        ? `-${value}`
        : value;
}
