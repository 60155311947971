import styled from '@emotion/styled';
import Text, { IText } from '../../components/Text';

export type ICaption = IText;

const Caption = styled(Text)<ICaption>();

Caption.displayName = 'Caption';
Caption.defaultProps = {
    as: 'span',
    clamp: false,
    color: 'black',
    fontWeight: 'bold',
    variant: 'caption',
};

export default Caption;
