import variant from '@styled-system/variant';

// TODO: Find out from Mark if he wants to continue using this mix of colours.
// TODO: If so, should they be added to the palette?

export const variants = variant({
    variants: {
        notClaimed: {
            bg: 'border',
            'span, svg': {
                color: 'disabled',
            },
        },
        claimed: {
            bg: 'semantic.success.secondary',
            'span, svg': {
                color: 'semantic.success.primary',
            },
        },
        premium: {
            bg: 'semantic.warning.secondary',
            'span, svg': {
                color: '#997400',
            },
        },
        great: {
            bg: '#C2F0D7',
            'span, svg': {
                color: '#268C55',
            },
        },
        okay: {
            bg: '#F6C000',
            'span, svg': {
                color: '#997400',
            },
        },
        poor: {
            bg: 'semantic.error.secondary',
            'span, svg': {
                color: 'semantic.error.primary',
            },
        },
    },
});
