import styled from '@emotion/styled';
import Button, { IButton } from '../Button';

export type IIconButton = IButton

const IconButton = styled(Button)<IIconButton>();

IconButton.displayName = 'IconButton';
IconButton.defaultProps = {
    disabled: false,
    onClick: () => {},
    size: 'base',
    variant: 'primary',
};

export default IconButton;
