import styled from '@emotion/styled';
import Heading, { IHeading } from '../Heading';

export type IH3 = IHeading;

const H3 = styled(Heading)<IH3>();

H3.displayName = 'H3';
H3.defaultProps = {
    as: 'h3',
    color: 'black',
    clamp: false,
    variant: 'h3',
};

export default H3;
