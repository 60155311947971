export const base = {
    appearance: 'none',
    display: 'block',
    boxSizing: 'border-box',
    m: 0,
    p: 3,
    minWidth: 0,
    width: 'full',
    bg: 'white',
    color: 'black',
    border: '2px solid',
    borderRadius: 'field',
    boxShadow: 'field.default',
    fontFamily: 'body',
    fontSize: 'small',
    lineHeight: 'small',
    letterSpacing: 'small',
    outline: 0,
    transitionProperty:
        'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
    transitionTimingFunction: 'ease-in-out',
    transitionDuration: '150ms',
    ':placeholder': {
        color: 'placeholder',
    },
    ':not(:disabled):hover': {
        boxShadow: 'field.hover',
    },
    ':not(:disabled):focus': {
        borderColor: 'brand.secondary',
        boxShadow: 'none',
    },
    ':not(:disabled):active': {
        borderColor: 'brand.primary',
        boxShadow: 'none',
    },
    ':disabled': {
        bg: 'border',
        borderColor: 'placeholder',
        color: 'disabled',
        cursor: 'not-allowed',
        ':placeholder': {
            color: 'disabled',
        },
    },
};
