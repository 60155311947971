import { compose, Config, system } from '@styled-system/core';
import { config as marginConfig } from 'styles/margin';
import { config as paddingConfig } from 'styles/padding';

const config: Config = {
    ...marginConfig,
    ...paddingConfig,
};
const space = system(config);
const spaceProps = compose(space).propNames;

export { config, space, spaceProps };
