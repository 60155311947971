import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    left: {
        property: 'left',
        scale: 'space',
    },
};
const left = system(config);
const leftProps = compose(left).propNames;

export { config, left, leftProps };
