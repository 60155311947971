import {
    AuthStateActionTypes,
    GET_USER_FAILED,
    GET_USER_SUCCESS,
    IAuthState,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
} from "./types";

const initialState: IAuthState = {
    isAuthenticated: false,
    isLoading: true,
    loginErrors: [],
    user: undefined,
};

export const userReducer = (
    state = initialState,
    action: AuthStateActionTypes
): IAuthState => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                loginErrors: [],
                user: action.user,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                loginErrors: action.errors,
                user: undefined,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                user: undefined,
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.user,
            };
        case GET_USER_FAILED:
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                user: undefined,
            };
        default:
            return state;
    }
};
