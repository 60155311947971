import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    top: {
        property: 'top',
        scale: 'space',
    },
};
const top = system(config);
const topProps = compose(top).propNames;

export { config, top, topProps };
