import styled from '@emotion/styled';
import Text, { IText } from '../Text';

export type IParagraph = IText;

const Paragraph = styled(Text)<IParagraph>();

Paragraph.displayName = 'Paragraph';
Paragraph.defaultProps = {
    as: 'p',
    color: 'black',
    clamp: false,
    variant: 'paragraph',
};

export default Paragraph;
