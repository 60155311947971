import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import {
    ColorsProps,
    LayoutProps,
    PositionProps,
    SpaceProps,
    SxProp,
    TypographyProps,
} from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { colors, colorsProps } from 'styles/collections/colors';
import { layout, layoutProps } from 'styles/collections/layout';
import { typography, typographyProps } from 'styles/collections/typography';
import { flexbox, flexboxProps } from 'styles/collections/flexbox';
import { position, positionProps } from 'styles/collections/position';
import { base, variants as textVariantStyles } from './Text.styles';

export const textProps = createShouldForwardProp([
    ...(colorsProps as string[]),
    ...(layoutProps as string[]),
    ...(spaceProps as string[]),
    ...(flexboxProps as string[]),
    ...(typographyProps as string[]),
    ...(positionProps as string[]),
]);

export interface IText
    extends SxProp,
        ColorsProps,
        SpaceProps,
        TypographyProps,
        LayoutProps,
        PositionProps {
    clamp?: boolean;
    variant?: 'paragraph' | 'small' | 'caption';
}
const Text = styled('p', {
    shouldForwardProp: textProps,
})<IText>(
    ({ clamp, theme }) =>
        css(
            clamp
                ? {
                      ...base,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 'vertical' as any,
                      WebkitBoxOrient: 'break-word' as any,
                  }
                : base,
        )(theme),
    compose(
        textVariantStyles,
        colors,
        space,
        typography,
        layout,
        flexbox,
        position,
    ),
    ({ sx, theme }) => css(sx)(theme),
);
Text.displayName = 'Text';
Text.defaultProps = {
    as: 'p',
    clamp: false,
    variant: 'paragraph',
};

export default Text;
