import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    bottom: {
        property: 'bottom',
        scale: 'space',
    },
};
const bottom = system(config);
const bottomProps = compose(bottom).propNames;

export { config, bottom, bottomProps };
