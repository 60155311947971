import { Admin } from "./components";
import { LoginPage } from "./components/auth/login";
import { Route, Switch } from "react-router";

const App = () => (
    <div className="App">
        <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/" component={Admin} />
        </Switch>
    </div>
);

export default App;
