import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import { space, spaceProps } from 'styles/collections/space';
import { position, positionProps } from 'styles/collections/position';
import { PositionProps, SpaceProps, SxProp } from '../../types';
import Box from '../Box';
import Caption from '../Caption';
import StarIcon from '../icons/Star';
import ShieldIcon from '../icons/Shield';
import { variants as pillVariantStyles } from './Pill.styles';

export const pillProps = createShouldForwardProp([
    ...(spaceProps as string[]),
    ...(positionProps as string[]),
]);

const labels = {
    notClaimed: 'Not claimed',
    claimed: 'Claimed',
    premium: 'Premium',
    great: 'Great',
    okay: 'Okay',
    poor: 'Poor',
};
const icons = {
    claimed: {
        icon: StarIcon,
        viewBox: '0 0 26 26',
    },
    premium: {
        icon: ShieldIcon,
        viewBox: '0 0 16 13',
    },
};

export interface IPill extends SxProp, PositionProps, SpaceProps {
    label?: string;
    // Should these variants just be the semantic values?
    variant?: 'notClaimed' | 'claimed' | 'premium' | 'great' | 'okay' | 'poor';
}
const StyledPill = styled(Box)<IPill>(
    ({ theme }) =>
        css({
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 'circle',
            px: 3,
            py: 2,
            userSelect: 'none',
            whiteSpace: 'nowrap',
            svg: {
                position: 'relative',
            },
        })(theme),
    compose(pillVariantStyles, position, space),
);

export const Pill = ({ label, variant = 'notClaimed', ...props }: IPill) => {
    const variantIcon = (icons as any)[variant];
    const PillIcon = variantIcon?.icon || null;

    return (
        <StyledPill variant={variant} {...props}>
            {PillIcon ? (
                <PillIcon size={14} mr={1} viewBox={variantIcon?.viewBox} />
            ) : null}
            <Caption>{label ? label : (labels as any)[variant]}</Caption>
        </StyledPill>
    );
};
Pill.displayName = 'Pill';

export default Pill;
