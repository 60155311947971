import { compose, Config, system } from '@styled-system/core';

const config: Config = {
    minWidth: {
        property: 'minWidth',
        scale: 'sizes',
    },
};
const minWidth = system(config);
const minWidthProps = compose(minWidth).propNames;

export { config, minWidth, minWidthProps };
