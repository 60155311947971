import { Dispatch } from "redux";

import { getIndustries } from "../../api/industry";
import { GET_INDUSTRIES_FAILED, GET_INDUSTRIES_SUCCESS } from "./types";

export const getIndustriesAction = () => async (dispatch: Dispatch) => {
    try {
        const response = await getIndustries();
        const industries = await response.data;
        dispatch({ type: GET_INDUSTRIES_SUCCESS, industries });
    } catch (e) {
        dispatch({ type: GET_INDUSTRIES_FAILED });
    }
};
