import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import {
    ColorsProps,
    LayoutProps,
    SpaceProps,
    SxProp,
    TypographyProps,
} from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { colors, colorsProps } from 'styles/collections/colors';
import { layout, layoutProps } from 'styles/collections/layout';
import { typography, typographyProps } from 'styles/collections/typography';
import Box from '../Box';
import Flex from '../Flex';
import StepperItem from './StepperItem';
import Caption from '../Caption';
import { TickIcon } from '../icons';
import BaseButton from '../Button/Base';

export const stepperProps = createShouldForwardProp([
    ...(colorsProps as string[]),
    ...(layoutProps as string[]),
    ...(spaceProps as string[]),
    ...(typographyProps as string[]),
]);

export interface IStepper
    extends Omit<React.ComponentPropsWithoutRef<'div'>, 'color' | 'onClick'>,
        SxProp,
        ColorsProps,
        SpaceProps,
        TypographyProps,
        LayoutProps {
    current: number;
    onClick?: (step: number) => void;
}

const Container: React.ComponentType = styled(Box)(
    ({ theme }) => css({ position: 'relative', borderRadius: 'circle' })(theme),
    compose(colors, space, typography, layout),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

const Stepper: React.ComponentType<IStepper> = ({
    children,
    current,
    onClick,
    ...props
}) => {
    const itemCount = React.Children.count(children);

    if (current < 0 || current > itemCount) {
        throw new Error(
            `[Stepper] "current" must be between the following range: 0 - ${itemCount}`,
        );
    }

    if (itemCount === 0) {
        throw new Error('[Stepper] Missing children');
    }

    if (
        !React.Children.toArray(children).every(
            (child) =>
                React.isValidElement(child) && child.type === StepperItem,
        )
    ) {
        throw new Error(
            '[Stepper] Only accepts children of type "StepperItem"',
        );
    }

    let width;
    const block = 100 / itemCount;
    width = block * current + block / 2;

    if (current === itemCount) width = 100;

    return (
        <Container {...props}>
            <Flex
                as="ul"
                bg="grey"
                p={0}
                flex={1}
                sx={{
                    position: 'relative',
                    listStyle: 'none',
                    boxShadow: 'inset 0 0 0 2px rgba(255, 255, 255, 1)',
                    borderRadius: 'circle',
                }}
            >
                <Box
                    as="li"
                    width={`${width}%`}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        background:
                            'linear-gradient(135deg, #00AE31 25%, #BFF6C8 75%)',
                        borderTopLeftRadius: 'inherit',
                        borderBottomLeftRadius: 'inherit',
                        borderTopRightRadius:
                            current === itemCount ? 'inherit' : null,
                        borderBottomRightRadius:
                            current === itemCount ? 'inherit' : null,
                        boxShadow: 'inherit',
                    }}
                />
                {React.Children.map(
                    children,
                    (child, index) =>
                        React.isValidElement(child) &&
                        child.type === StepperItem && (
                            <Flex
                                as="li"
                                flex={1}
                                direction="column"
                                justifyContent="center"
                                sx={{
                                    position: 'relative',
                                    alignItems:
                                        index === 0
                                            ? 'flex-start'
                                            : index === itemCount
                                            ? 'flex-end'
                                            : 'center',
                                }}
                            >
                                <BaseButton
                                    display="contents"
                                    onClick={() =>
                                        onClick ? onClick(index) : null
                                    }
                                >
                                    <Caption
                                        bg={
                                            current >= index
                                                ? 'semantic.success.primary'
                                                : 'white'
                                        }
                                        color={
                                            current >= index
                                                ? 'white'
                                                : 'placeholder'
                                        }
                                        display="flex"
                                        width={5}
                                        height={5}
                                        sx={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            border: '2px solid',
                                            borderColor:
                                                current >= index
                                                    ? 'semantic.success.secondary'
                                                    : 'placeholder',
                                            borderRadius: 'circle',
                                            boxShadow:
                                                '0 2px 2px 0 rgba(0, 0, 0, 0.16)',
                                        }}
                                    >
                                        {current > index ? (
                                            <TickIcon size={16} color="white" />
                                        ) : (
                                            index + 1
                                        )}
                                    </Caption>
                                </BaseButton>
                            </Flex>
                        ),
                )}
            </Flex>
            <Flex as="ul" mt={3} p={0} sx={{ flex: 1, listStyle: 'none' }}>
                {React.Children.map(
                    children,
                    (child, index) =>
                        React.isValidElement(child) &&
                        child.type === StepperItem && (
                            <Flex
                                as="li"
                                flex={1}
                                direction="column"
                                justifyContent="center"
                                sx={{
                                    position: 'relative',
                                    alignItems:
                                        index === 0
                                            ? 'flex-start'
                                            : index === itemCount
                                            ? 'flex-end'
                                            : 'center',
                                }}
                            >
                                <Box as="li">
                                    {React.cloneElement(child, {
                                        current,
                                        index,
                                    })}
                                </Box>
                            </Flex>
                        ),
                )}
            </Flex>
        </Container>
    );
};

Stepper.displayName = 'Stepper';
Stepper.defaultProps = {
    current: 0,
    onClick: () => {},
};

export { StepperItem };
export default Stepper;
