import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import { ColorsProps, SpaceProps, SxProp, TypographyProps } from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { colors, colorsProps } from 'styles/collections/colors';
import { typography, typographyProps } from 'styles/collections/typography';
import Small from '../Small';

export const labelProps = createShouldForwardProp([
    ...(colorsProps as string[]),
    ...(spaceProps as string[]),
    ...(typographyProps as string[]),
]);

export interface ILabel
    extends Omit<React.ComponentPropsWithoutRef<'label'>, 'color'>,
        SxProp,
        ColorsProps,
        SpaceProps,
        TypographyProps {}

const Label = styled(Small)<ILabel>(
    ({ theme }) =>
        css({
            display: 'block',
            fontSize: 'small',
            fontWeight: 'bold',
            lineHeight: 'small',
            letterSpacing: 'small',
        })(theme),
    compose(colors, space, typography),
    ({ sx, theme }) => css(sx)(theme),
);
Label.displayName = 'Label';
Label.defaultProps = {
    as: 'label',
};

export default Label;
