import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import {
    ColorsProps,
    LayoutProps,
    PositionProps,
    SpaceProps,
    SxProp,
    TypographyProps,
} from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { colors, colorsProps } from 'styles/collections/colors';
import { layout, layoutProps } from 'styles/collections/layout';
import { typography, typographyProps } from 'styles/collections/typography';
import { position, positionProps } from 'styles/collections/position';
import {
    base,
    sizes as buttonSizeStyles,
    variants as buttonVariantStyles,
} from './Button.styles';

export const buttonProps = createShouldForwardProp([
    ...(colorsProps as string[]),
    ...(layoutProps as string[]),
    ...(spaceProps as string[]),
    ...(typographyProps as string[]),
    ...(positionProps as string[]),
]);

export interface IButton
    extends Omit<React.ComponentPropsWithoutRef<'button'>, 'color'>,
        SxProp,
        ColorsProps,
        SpaceProps,
        TypographyProps,
        LayoutProps,
        PositionProps {
    disabled?: boolean;
    size?: 'small' | 'base' | 'large';
    variant?: 'primary' | 'secondary' | 'tertiary' | 'success';
}

const Button = styled('button', {
    shouldForwardProp: buttonProps,
})<IButton>(
    ({ theme }) => css(base)(theme),
    compose(
        buttonSizeStyles,
        buttonVariantStyles,
        colors,
        space,
        typography,
        layout,
        position,
    ),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

Button.displayName = 'Button';
Button.defaultProps = {
    disabled: false,
    onClick: () => {},
    size: 'base',
    variant: 'primary',
};

export default Button;
