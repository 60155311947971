import { useState } from "react";

import styled from "styled-components";
import { IUser } from "../../api/interfaces/user";
import { getUser } from "../../api/user";
import config from "../../config";

interface IProps {
    type: "signup" | "login";
    provider: string;
    onSuccess: (user: IUser) => void;
}

export const OAuth = ({ provider, type, onSuccess }: IProps) => {
    let popup: Window | null = null;
    const [disabled, setDisabled] = useState(false);
    const capitalizedProvider =
        provider.charAt(0).toUpperCase() + provider.slice(1);

    const checkPopup = () => {
        const check = setInterval(async () => {
            if (!popup || popup.closed || popup.closed === undefined) {
                clearInterval(check);
                try {
                    const user = await getUser();
                    onSuccess(user.data);
                } catch {
                    setDisabled(false);
                }
            }
        }, 500);
    };

    const openPopup = () => {
        const width = 600;
        const height = 600;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
        const url = `${config.API_URL}/api/auth/${provider}`;

        return window.open(
            url,
            "",
            `toolbar=no, location=no, directories=no, status=no, menubar=no,
      scrollbars=no, resizable=no, copyhistory=no, width=${width},
      height=${height}, top=${top}, left=${left}`
        );
    };

    const startAuth = () => {
        if (!disabled) {
            popup = openPopup();
            checkPopup();
            setDisabled(true);
        }
    };

    return (
        <LoginButton
            provider={provider}
            text={
                type === "signup"
                    ? `Sign up with ${capitalizedProvider}`
                    : `Log in with ${capitalizedProvider}`
            }
            disabled={disabled}
            onClick={startAuth}
            className={`${provider} ${disabled} button`}
        >
            {provider}
        </LoginButton>
    );
};

interface IButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    provider: string;
    text: string;
    className?: string;
}

const LoginButton = styled(
    ({ provider, text, className, ...rest }: IButtonProps) => {
        return (
            <button className={`${className} ${provider}`} {...rest}>
                <div className="logo" />
                <div className="text">{text}</div>
            </button>
        );
    }
)`
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.fontColors.main};
    display: flex;
    justify-content: center;
    margin: 10px 0 0;
    width: 100%;
    border: 2px solid ${(props) => props.theme.card.border};
    padding: 0;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    height: 55px;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    .logo {
        height: 30px;
        width: 30px;
        margin: 3px;
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 5px;
    }

    &.google {
        background-color: ${(props) => props.theme.fontColors.white};

        &:hover {
            background-color: #3367d6;
            color: ${(props) => props.theme.fontColors.white};
        }

        .logo {
            background-color: ${(props) => props.theme.fontColors.white};
            background-size: 26px;
        }
    }

    &.facebook {
        background-color: ${(props) => props.theme.fontColors.white};

        &:hover {
            background-color: #4267b2;
            color: ${(props) => props.theme.fontColors.white};
        }

        .logo {
        }
    }

    &.linkedin {
        background-color: ${(props) => props.theme.fontColors.white};

        &:hover {
            background-color: rgb(49, 115, 174);
            color: ${(props) => props.theme.fontColors.white};
        }

        .logo {
            background-size: 24px;
            background-color: rgb(49, 115, 174);
            padding: 1px;
        }
    }

    .text {
        padding: 10px;
    }
`;
