import { compose, Config, system } from '@styled-system/core';
import { config as fontFamilyConfig } from 'styles/fontFamily';
import { config as fontSizeConfig } from 'styles/fontSize';
import { config as fontStyleConfig } from 'styles/fontStyle';
import { config as fontWeightConfig } from 'styles/fontWeight';
import { config as lineHeightConfig } from 'styles/lineHeight';
import { config as letterSpacingConfig } from 'styles/letterSpacing';
import { config as textAlignConfig } from 'styles/textAlign';
import { config as textDecorationConfig } from 'styles/textDecoration';
import { config as textTransformConfig } from 'styles/textTransform';
import { config as whiteSpaceConfig } from 'styles/whiteSpace';

const config: Config = {
    ...fontFamilyConfig,
    ...fontSizeConfig,
    ...fontStyleConfig,
    ...fontWeightConfig,
    ...lineHeightConfig,
    ...letterSpacingConfig,
    ...textAlignConfig,
    ...textDecorationConfig,
    ...textTransformConfig,
    ...whiteSpaceConfig,
};
const typography = system(config);
const typographyProps = compose(typography).propNames;

export { config, typography, typographyProps };
