import * as React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import { SpaceProps, SxProp, TypographyProps } from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { typography, typographyProps } from 'styles/collections/typography';
import Radio from '../Radio';
import InlineFlex from '../InlineFlex';

export const radioGroupProps = createShouldForwardProp([
    ...(spaceProps as string[]),
    ...(typographyProps as string[]),
]);

export interface IRadioGroup
    extends Omit<React.ComponentPropsWithoutRef<'div'>, 'color'>,
        SxProp,
        SpaceProps,
        TypographyProps {
    gap?: number;
    stack?: boolean;
    wrap?: 'inherit' | 'initial' | 'unset' | 'nowrap' | 'wrap' | 'wrap-reverse';
}

const Container = styled(InlineFlex, {
    shouldForwardProp: radioGroupProps,
})<IRadioGroup>(
    ({ gap, stack, wrap, theme }) =>
        css({
            alignItems: 'flex-start',
            flexDirection: stack ? 'column' : 'row',
            flexWrap: wrap,
            mt: `calc(-1 * ${gap}px)`,
            ml: `calc(-1 * ${gap}px)`,
            width: `calc(100% + ${gap}px)`,
            '> *': {
                cursor: 'pointer',
                mt: `${gap}px`,
                ml: `${gap}px`,
            },
        })(theme),
    compose(space, typography),
    ({ sx, theme }) => css(sx)(theme),
    ({ css }) => css,
);

const RadioGroup: React.ComponentType<IRadioGroup> = ({
    children,
    ...props
}) => {
    if (React.Children.count(children) === 0) {
        throw new Error('[RadioGroup] Missing children');
    }

    if (
        React.Children.toArray(children).some(
            (child) => React.isValidElement(child) && child.type !== Radio,
        )
    ) {
        throw new Error('[RadioGroup] Only accepts children of type "Radio"');
    }

    return (
        <Container {...props}>
            {React.Children.map(
                children,
                (child) =>
                    React.isValidElement(child) &&
                    child.type === Radio &&
                    child,
            )}
        </Container>
    );
};

RadioGroup.displayName = 'RadioGroup';
RadioGroup.defaultProps = {
    gap: 8,
    stack: false,
    wrap: 'wrap',
};

export default RadioGroup;
