import styled from "styled-components";

export const TextArea = styled.textarea`
    font-size: 16px;
    display: flex;
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.fontColors.main};
    background-color: rgba(255, 255, 255, 0.3);
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    padding: 1rem;
`;
