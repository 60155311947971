import styled from "styled-components";
import { BackgroundColor, getBackgroundColor } from "../utils";

interface ICheckboxProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label: string;
    className?: string;
}

const UnstyledCheckbox = ({ label, className, ...props }: ICheckboxProps) => {
    return (
        <label className={className}>
            <input type="checkbox" {...props} />
            <span className="checkmark" />
            <span className="text">{label}</span>
        </label>
    );
};

interface IUnstyledCheckbox {
    color?: BackgroundColor;
}

export const Checkbox = styled(UnstyledCheckbox)<IUnstyledCheckbox>`
    display: flex;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    align-items: center;

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: relative;
        height: 24px;
        width: 24px;
        border-radius: 2px;
        border: 1px solid
            ${(props) => getBackgroundColor(props.theme, props.color)};

        &::after {
            content: "";
            position: absolute;
            display: none;
            left: 8px;
            top: 0;
            width: 7px;
            height: 19px;
            border: solid white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: ${(props) => props.theme.iconColors.selected};
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: ${(props) => props.theme.iconColors.selected};
        border-color: ${(props) => props.theme.iconColors.selected};
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    .text {
        flex: 1;
        margin-left: 0.5rem;
    }
`;
