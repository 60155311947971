import { FunctionComponent } from "react";
import styled from "styled-components";
import { Description } from "../text/description";
import { Text } from "../text";

interface IRadioCheckboxProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label: string;
    description?: string;
    className?: string;
}

const UnstyledRadio: FunctionComponent<IRadioCheckboxProps> = ({
    className,
    label,
    description,
    ...rest
}) => {
    return (
        <label className={className}>
            <input type="radio" {...rest} />
            <span className="checkmark" />
            <span className="text">{label}</span>
            {description && (
                <Text>
                    <Description>{description}</Description>
                </Text>
            )}
        </label>
    );
};

export const Radio = styled(UnstyledRadio)`
    display: flex;
    font-size: 16px;
    height: 24px;
    margin-bottom: 1rem;
    cursor: pointer;
    user-select: none;
    align-items: center;
    position: relative;

    /* Hide the browser's default radio button */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: relative;
        height: 24px;
        width: 24px;
        background-color: #eee;
        border-radius: 50%;

        &::before {
            content: "";
            position: absolute;
            display: none;
            top: 2px;
            left: 2px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: white;
        }

        &::after {
            content: "";
            position: absolute;
            display: none;
            top: 6px;
            left: 6px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: white;
        }
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: ${(props) => props.theme.iconColors.selected};
    }

    input:checked ~ .checkmark {
        background-color: ${(props) => props.theme.iconColors.selected};
    }

    input:checked ~ .checkmark::before {
        display: block;
        background: white;
    }

    input:checked ~ .checkmark::after {
        display: block;
        background: ${(props) => props.theme.iconColors.selected};
    }

    .text {
        flex: 1;
        margin-left: 0.5rem;
    }
`;

interface IRadioButtonProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label: string;
    id: string;
    className?: string;
}

const UnstyledRadioButton: FunctionComponent<IRadioButtonProps> = ({
    className,
    label,
    id,
    ...rest
}) => {
    return (
        <div className={className}>
            <input id={id} type="radio" {...rest} />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export const RadioButton = styled(UnstyledRadioButton)`
    label {
        display: block;
        background-color: transparent;
        cursor: pointer;
        user-select: none;
        padding: 1rem;
        color: ${(props) => props.theme.fontColors.main};
        font-weight: bold;
        text-align: center;
        border: 2px solid ${(props) => props.theme.buttonColors.main};
        border-radius: 100px;

        &:hover {
            background-color: ${(props) => props.theme.buttonColors.main};
            color: ${(props) => props.theme.fontColors.white};
        }
    }

    /* Hide the browser's default radio button */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    input:checked ~ label {
        background-color: ${(props) => props.theme.buttonColors.main};
        color: ${(props) => props.theme.fontColors.white};
    }
`;
