import {
    CountryStateActionTypes,
    GET_COUNTRIES_FAILED,
    GET_COUNTRIES_SUCCESS,
    ICountriesState,
} from "./types";

const initialState: ICountriesState = {
    countries: {},
    isLoading: true,
};

export const countryReducer = (
    state = initialState,
    action: CountryStateActionTypes
): ICountriesState => {
    switch (action.type) {
        case GET_COUNTRIES_SUCCESS:
            return {
                countries: action.countries,
                isLoading: false,
            };
        case GET_COUNTRIES_FAILED:
            return {
                countries: {},
                isLoading: true,
            };
        default:
            return state;
    }
};
