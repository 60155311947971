import { LocationChangeAction } from "connected-react-router";
export const CLEAR_BANNER = "CLEAR_BANNER";
export const SHOW_ERROR_BANNER = "SHOW_ERROR_BANNER";
export const SHOW_SUCCESS_BANNER = "SHOW_SUCCESS_BANNER";

export type BannerType = "error" | "success";

export interface IBannerState {
    current?: {
        type: BannerType;
        message: string;
    };
}

interface IClearBanner {
    type: typeof CLEAR_BANNER;
}

interface IShowErrorBanner {
    type: typeof SHOW_ERROR_BANNER;
    message: string;
}

interface IShowSuccessBanner {
    type: typeof SHOW_SUCCESS_BANNER;
    message: string;
}

export type BannerStateActionTypes =
    | IClearBanner
    | IShowErrorBanner
    | IShowSuccessBanner
    | LocationChangeAction;
