import styled from '@emotion/styled';
import Box, { IBox } from '../Box';

export type IInlineFlex = IBox;

const InlineFlex = styled(Box)<IBox>({ display: 'inline-flex' });

InlineFlex.displayName = 'InlineFlex';

export default InlineFlex;
