import * as React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { compose } from '@styled-system/core';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import {
    LayoutProps,
    PositionProps,
    SpaceProps,
    SxProp,
    TypographyProps,
} from '../../types';
import { space, spaceProps } from 'styles/collections/space';
import { layout, layoutProps } from 'styles/collections/layout';
import { typography, typographyProps } from 'styles/collections/typography';
import { position, positionProps } from 'styles/collections/position';
import Box from '../Box';
import Label from '../Label';
import Small from '../Small';
import Input from '../Input';
import TextArea from '../Textarea';

type FieldType = typeof Input | typeof TextArea;

export interface IFormField
    extends Omit<SxProp, 'as'>,
        SpaceProps,
        TypographyProps,
        LayoutProps,
        PositionProps {
    as: FieldType;
    defaultValue?: string;
    disabled?: boolean;
    error?: boolean;
    errorMessage?: string;
    id: string;
    label: string | React.ReactNode;
    labelProps?: object;
    name: string;
    placeholder?: string;
    type?: string;
    onBlur?: React.FocusEventHandler<HTMLInputElement> &
        React.FocusEventHandler<HTMLTextAreaElement>;
}

export const formFieldProps = createShouldForwardProp([
    ...(spaceProps as string[]),
    ...(layoutProps as string[]),
    ...(typographyProps as string[]),
    ...(positionProps as string[]),
]);

const Container = styled(Box, {
    shouldForwardProp: formFieldProps,
})(
    ({ theme }) => css({ '> * + *': { mt: '8px !important' } })(theme),
    compose(space, typography, layout, position),
    ({ sx, theme }) => css(sx)(theme),
);

const FormField = React.forwardRef<any, IFormField>(
    (
        {
            as: Field,
            defaultValue,
            disabled,
            error,
            errorMessage,
            id,
            label,
            labelProps,
            name,
            placeholder,
            type,
            onBlur,
            ...props
        },
        ref,
    ) => (
        <Container {...props}>
            <Label htmlFor={id} {...labelProps}>
                {label}
            </Label>
            <Field
                ref={ref}
                id={id}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                defaultValue={defaultValue}
                error={error}
                type={type}
                onBlur={onBlur}
            />
            {error && errorMessage && (
                <Small color="semantic.error.primary" mt={3}>
                    {errorMessage}
                </Small>
            )}
        </Container>
    ),
);

FormField.displayName = 'FormField';

export default FormField;
