import { push } from "connected-react-router";
import { Dispatch } from "redux";

import { logout } from "../../api/auth";
import { IUser } from "../../api/interfaces/user";
import { getUser } from "../../api/user";
import { showSuccessBanner } from "../banner/actions";
import {
    GET_USER_FAILED,
    GET_USER_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
} from "./types";

export const getUserAction = (isLogin = false) => async (
    dispatch: Dispatch
) => {
    try {
        const response = await getUser();
        const user = await response.data;
        dispatch({ type: GET_USER_SUCCESS, user });
        if (isLogin) {
            dispatch(showSuccessBanner("banner:auth.login.success"));
        }
    } catch (e) {
        dispatch({ type: GET_USER_FAILED });
    }
};

export const logoutAction = () => async (dispatch: Dispatch) => {
    await logout();
    dispatch(logoutSuccessAction());
    dispatch(push("/"));
};

export const loginSuccessAction = (user: IUser) => {
    return {
        type: LOGIN_SUCCESS,
        user,
    };
};

const logoutSuccessAction = () => {
    return {
        type: LOGOUT_SUCCESS,
    };
};
